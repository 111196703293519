import React, {useState} from 'react';
import { Table } from 'antd';

export default function AssignMaterial({materialList, addIndentMaterial, selectedMaterial}) {
  const [selectRows, setSelectRows] = useState(selectedMaterial ? selectedMaterial : []);
  console.log(selectRows);
  const rowSelection = {
    
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  const addMat = () => {
    addIndentMaterial(selectRows);
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'BOQ No.',
      dataIndex: 'boq_no',
      key: 'boq',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Available Stock',
      dataIndex: 'stock',
      key: 'stock',
    }
  ]
  return (
    <>
      <Table 
        rowSelection={rowSelection} 
        pagination={{ pageSize: 6}} 
        rowKey={(record) => record.material_id} 
        className='w-full text-left' 
        dataSource={materialList} 
        columns={columns} 
      />
      <button onClick={() => addMat(rowSelection)} className='bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase'>Add</button>
    </>
  )
}
