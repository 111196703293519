import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader, Loader } from "../../components/utils/Loader";
import { getProjectList } from "../../queries/Projects";
import { getIndentById, indentApproval } from "../../queries/Indent";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { Modal, Table, InputNumber } from "antd";
import AssignMaterial from "./AssignMaterial";
import moment from "moment";
import toast from "react-hot-toast";
import { useParams, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Empty } from "antd";
import { useQueryMutation } from "../../hooks/useMutation";


export default function ApproveIndent(props) {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const params = useParams();
    const [projectName, setProjectName] = useState("");
    const [isReject, setIsReject] = useState(false);
    const [comments, setComments] = useState('');
    const queryClient = useQueryClient();
    const {
        data: indentData,
        refetch,
        isError,
        isLoading,
    } = useQuery({
        queryKey: ["indent", params.id],
        queryFn: () => getIndentById(params.id),
        enabled: !!params.id,
        refetchOnWindowFocus: true,
        refetchOnMount: true, // Refetch on component mount
        staleTime: 0,        // Always mark the data as stale
        cacheTime: 0,
    });
    const { data: projectData } = useQuery({
        queryKey: ["project"],
        queryFn: getProjectList,
    });
    const navigate = useNavigate();

    useEffect(() => {
        refetch();
    }, [params.id]);

    const approvedIntent = useQueryMutation(indentApproval, queryClient, 'indent');

    const approveIndent = (status) => {
        if (status === 'rejected' && comments === '') {
            toast.error('Please Enter your Reason for Reject this Intent');
            return;
        }
        const payload = {
            body: {
                approver: userData?._id,
                status: status,
                comment: comments    
            },
            id: params.id
        }
        approvedIntent.mutate(payload);
        //navigate('/dashboard')
    }

    useEffect(() => {
        if (projectData && projectData?.result) {
            const project = projectData?.result.find(
                (project) => project._id === indentData?.result?.project_id
            );
            setProjectName(project?.name);
        }
    }, [projectData, indentData]);

    if (isLoading) {
        return <Loader />;
    }
    if (!indentData || !indentData.result) {
        return (
            <div className="py-44">
                <Empty />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading data</div>;
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "15%",
        },
        {
            title: "BOQ No.",
            dataIndex: "boq_no",
            key: "boq_no",
            width: "10%",
        },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     key: "description",
        //     width: "15%",
        // },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            width: "10%",
        },
        {
            title: "Unit",
            dataIndex: "unit",
            key: "unit",
            width: "10%",
        },
        // {
        //     title: "Model",
        //     dataIndex: "model",
        //     key: "model",
        //     width: "10%",
        // },
        {
            title: "QTY",
            dataIndex: "quantity",
            key: "qty",
            width: "15%",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            width: "15%",
        },
    ];

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
            <p className="mb-8 flex justify-end">
                <NavLink to="/notification">
                    <img src={closeIcon} alt="" />
                </NavLink>
            </p>
            <div className="flex">
                <div className="w-[20%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        Indent Approval
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        To Approve or Reject the Indent
                    </p>
                </div>
                <div className="w-[80%]">
                    <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                        <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                            Indent Details
                        </h4>
                        <div className="grid grid-cols-4 gap-8 text-left mb-8">
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Project
                                </label>
                                <p>{projectName}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Required Date
                                </label>
                                <p>
                                    {moment(
                                        indentData?.result?.required_date
                                    ).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Description
                                </label>
                                <p>{indentData?.result?.description}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Status
                                </label>
                                <p>{indentData?.result?.approval_status}</p>
                            </div>
                        </div>
                        <div className="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between">
                            <h4 className="text-[20px] text-left">
                                Assign Materials
                            </h4>
                        </div>
                        <div>
                            {indentData?.result?.materials?.length > 0 && (
                                <Table
                                    pagination={{ pageSize: 6 }}
                                    rowKey={(record) => record.material_id}
                                    className="w-full text-left"
                                    dataSource={indentData?.result?.materials}
                                    columns={columns}
                                />
                            )}
                        </div>
                    </div>
                    {indentData?.result?.approval_status === 'pending' && (
                        <p className="text-right mt-8 flex items-center justify-end">
                        <button onClick={() => setIsReject(true)} className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3">
                            Reject
                        </button>
                        <button
                            type="Submit"
                            className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                            onClick={() => approveIndent('approved')}
                        >
                            Approve
                        </button>
                    </p>
                    )}
                    
                </div>
            </div>
            {isReject && (
                <div
                id="billing-modal"
                tabindex="-1"
                aria-hidden="true"
                className="flex overflow-y-auto overflow-x-hidden bg-opacity-60 bg-black fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full"
            >
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Reason for Reject
                            </h3>
                            <button
                                type="button"
                                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="authentication-modal"
                                onClick={() => setIsReject(false)}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5">
                            <div>
                                <textarea
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    rows={5}
                                    placeholder="Comments"
                                    name=""
                                    id=""
                                    onInput={(e) => setComments(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div class="flex items-center justify-end md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[30px] w-[70px] border border-[#007AC8] rounded text-white text-[12px] font-bold uppercase"
                                onClick={() => approveIndent('rejected')}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            )}
            
        </div>
    );
}
