import React, { useState, useEffect } from "react";
import loginBg from "../assets/image/login-bg.jpg";
import logo from "../assets/image/sd-logo.png";
import { useForm } from "react-hook-form";
import { forgotPassword, loginUser } from "../queries/Auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { EMAIL_ONLY } from "../components/utils/Regex";
import { useQueryMutation } from "../hooks/useMutation";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error, isSuccess, data } = useMutation({
    mutationKey: ["login"],
    mutationFn: loginUser,
  });
  const [showForgotPass, setShowForgotPass] = useState(false);

  const {mutate: forgotPassQuery, isLoading: isForgotPassLoading} = useQueryMutation(forgotPassword, queryClient, "login");


  const loginFormSubmit = (data) => {
    let loginObj = {
      emp_code: data.userId,
      password: data.password,
    };
    mutate(loginObj);
  }
  const forgotFormSubmit = async (data) => {
    let forgotObj = {
        emp_code: data.userId,
        email: data.emailId,  
    };
    await forgotPassQuery(forgotObj, {
      onSuccess: () => {
        setShowForgotPass(false)
      }
    });
  }

  const onSubmit = showForgotPass ? forgotFormSubmit : loginFormSubmit;

  useEffect(() => {
    const entities = Cookies.get("authToken");
    if (entities) {
      navigate("/dashboard");
    }
    if (isError) {
      toast.error(error?.response?.data?.message);
    }
    if (isSuccess) {
      localStorage.setItem("loggedUser", JSON.stringify(data?.result));
      const expirationTime = 24 * 60 * 60;
      Cookies.set("authToken", data?.token, {
        expires: expirationTime,
      });
      navigate("/dashboard");
    }
  }, [error, data]);
  return (
    <div className="lg:flex">
      <div className="w-1/2 overflow-x-hidden lg:block hidden">
        <img className="object-cover w-full h-full" src={loginBg} alt="" />
      </div>
      <div className="lg:w-1/2 w-full grid justify-center items-end">
        <div className="lg:w-[500px] w-full px-10 lg:px-0">
          <p className="flex justify-center mb-6">
            <img src={logo} width="35%" alt="" />
          </p>

          {!showForgotPass ? (
            <>
              <div className="mb-8">
                <h1 className="text-[34px] text-[#2D3846] font-bold">
                  Welcome to SpaceDraft{" "}
                </h1>
                <p className="text-[12px] text-[#2B384A] mb-3">
                  Your central hub for collaboration, innovation, and
                  excellence.
                </p>
                <p className="text-[12px] text-[#2B384A]">
                  Log in to access tools, updates, and resources tailored for
                  every department at SpaceDraft. Together, we achieve
                  greatness.
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-left mb-5">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-[14px] font-medium text-[#2B384A]"
                  >
                    Emp Id
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 14.4C6.86842 14.4 4.76316 14.4 2.63158 14.4C1.02632 14.4 0 13.3696 0 11.7982C0 8.7585 0 5.69302 0 2.62755C0 1.00465 1.02632 0 2.68421 0C6.89474 0 11.1053 0 15.3158 0C16.9737 0 18 1.00465 18 2.62755C18 5.66726 18 8.70698 18 11.7467C18 13.3696 16.9737 14.3742 15.3158 14.3742C13.2105 14.4 11.1053 14.4 9 14.4ZM1.65789 2.62755C1.65789 2.91091 1.65789 3.06547 1.65789 3.22004C1.65789 6.05367 1.65789 8.91306 1.65789 11.7467C1.65789 12.5195 1.94737 12.8286 2.73684 12.8286C6.92105 12.8286 11.1053 12.8286 15.3158 12.8286C16.0526 12.8286 16.3684 12.5195 16.3684 11.824C16.3684 8.93882 16.3684 6.07943 16.3684 3.19428C16.3684 3.03971 16.3421 2.91091 16.3421 2.70483C16.1842 2.80787 16.0526 2.88515 15.9474 2.96243C13.8947 4.53381 11.8158 6.10519 9.76316 7.67656C9.13158 8.16601 8.86842 8.16601 8.26316 7.67656C6.73684 6.49159 5.18421 5.33238 3.65789 4.17317C3 3.65796 2.36842 3.16851 1.65789 2.62755ZM3.21053 1.6229C3.18421 1.67442 3.15789 1.70018 3.10526 1.7517C3.18421 1.80322 3.26316 1.80322 3.31579 1.85474C5.13158 3.2458 6.94737 4.61109 8.76316 6.00215C9 6.18247 9.13158 6.07943 9.31579 5.95063C11.0789 4.61109 12.8421 3.2458 14.6053 1.90626C14.6842 1.82898 14.7632 1.7517 14.9211 1.6229C10.9474 1.6229 7.07895 1.6229 3.21053 1.6229Z"
                          fill="#185D9E"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="userId"
                      className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Emp Id"
                      {...register("userId", {
                        required: "User Id is required",
                      })}
                      autoComplete="off"
                    />
                  </div>
                  {errors.userId && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.userId?.message}
                    </p>
                  )}
                </div>
                <div className="text-left">
                  <label
                    htmlFor="password"
                    className="mb-2 block text-[14px] font-medium text-[#2B384A]"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="6.75"
                          width="14.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      type="password"
                      id="password"
                      className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Password"
                      {...register("password", {
                        required: "Password is required",
                      })}
                      autoComplete="off"
                    />
                  </div>
                  {errors.password && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.password?.message}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-5 mb-8">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        for="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <button
                    onClick={() => setShowForgotPass(true)}
                    className="text-sm text-[#007AC8] font-bold"
                  >
                    Forgot password?
                  </button>
                </div>
                <p className="text-[12px] text-[#2B384A] mt-5 mb-3">
                  Let's shape the future as <strong>One Team!</strong>
                </p>
                <button
                  disabled={isLoading}
                  type="Submit"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "30px auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="font-bold disabled:opacity-25 text-[14px] bg-[#007AC8] w-full py-4 rounded-lg text-white"
                >
                  <div style={{ mr: 1 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      fill="white"
                      height="18"
                      viewBox="0 0 512 512"
                    >
                      <path d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
                    </svg>
                  </div>
                  Continue
                </button>
              </form>
            </>
          ) : (
            <>
              <div className="mb-8">
                <h1 className="text-[34px] text-[#2D3846] font-bold">
                  Forgot Password?
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="text-left mb-5">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-[14px] font-medium text-[#2B384A]"
                  >
                    Emp Id
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 14.4C6.86842 14.4 4.76316 14.4 2.63158 14.4C1.02632 14.4 0 13.3696 0 11.7982C0 8.7585 0 5.69302 0 2.62755C0 1.00465 1.02632 0 2.68421 0C6.89474 0 11.1053 0 15.3158 0C16.9737 0 18 1.00465 18 2.62755C18 5.66726 18 8.70698 18 11.7467C18 13.3696 16.9737 14.3742 15.3158 14.3742C13.2105 14.4 11.1053 14.4 9 14.4ZM1.65789 2.62755C1.65789 2.91091 1.65789 3.06547 1.65789 3.22004C1.65789 6.05367 1.65789 8.91306 1.65789 11.7467C1.65789 12.5195 1.94737 12.8286 2.73684 12.8286C6.92105 12.8286 11.1053 12.8286 15.3158 12.8286C16.0526 12.8286 16.3684 12.5195 16.3684 11.824C16.3684 8.93882 16.3684 6.07943 16.3684 3.19428C16.3684 3.03971 16.3421 2.91091 16.3421 2.70483C16.1842 2.80787 16.0526 2.88515 15.9474 2.96243C13.8947 4.53381 11.8158 6.10519 9.76316 7.67656C9.13158 8.16601 8.86842 8.16601 8.26316 7.67656C6.73684 6.49159 5.18421 5.33238 3.65789 4.17317C3 3.65796 2.36842 3.16851 1.65789 2.62755ZM3.21053 1.6229C3.18421 1.67442 3.15789 1.70018 3.10526 1.7517C3.18421 1.80322 3.26316 1.80322 3.31579 1.85474C5.13158 3.2458 6.94737 4.61109 8.76316 6.00215C9 6.18247 9.13158 6.07943 9.31579 5.95063C11.0789 4.61109 12.8421 3.2458 14.6053 1.90626C14.6842 1.82898 14.7632 1.7517 14.9211 1.6229C10.9474 1.6229 7.07895 1.6229 3.21053 1.6229Z"
                          fill="#185D9E"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="userId"
                      className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Emp Id"
                      {...register("userId", {
                        required: "Employee Id is required",
                      })}
                      autoComplete="off"
                    />
                  </div>
                  {errors.userId && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.userId?.message}
                    </p>
                  )}
                </div>
                <div className="text-left mb-5">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-[14px] font-medium text-[#2B384A]"
                  >
                    Email Id
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 14.4C6.86842 14.4 4.76316 14.4 2.63158 14.4C1.02632 14.4 0 13.3696 0 11.7982C0 8.7585 0 5.69302 0 2.62755C0 1.00465 1.02632 0 2.68421 0C6.89474 0 11.1053 0 15.3158 0C16.9737 0 18 1.00465 18 2.62755C18 5.66726 18 8.70698 18 11.7467C18 13.3696 16.9737 14.3742 15.3158 14.3742C13.2105 14.4 11.1053 14.4 9 14.4ZM1.65789 2.62755C1.65789 2.91091 1.65789 3.06547 1.65789 3.22004C1.65789 6.05367 1.65789 8.91306 1.65789 11.7467C1.65789 12.5195 1.94737 12.8286 2.73684 12.8286C6.92105 12.8286 11.1053 12.8286 15.3158 12.8286C16.0526 12.8286 16.3684 12.5195 16.3684 11.824C16.3684 8.93882 16.3684 6.07943 16.3684 3.19428C16.3684 3.03971 16.3421 2.91091 16.3421 2.70483C16.1842 2.80787 16.0526 2.88515 15.9474 2.96243C13.8947 4.53381 11.8158 6.10519 9.76316 7.67656C9.13158 8.16601 8.86842 8.16601 8.26316 7.67656C6.73684 6.49159 5.18421 5.33238 3.65789 4.17317C3 3.65796 2.36842 3.16851 1.65789 2.62755ZM3.21053 1.6229C3.18421 1.67442 3.15789 1.70018 3.10526 1.7517C3.18421 1.80322 3.26316 1.80322 3.31579 1.85474C5.13158 3.2458 6.94737 4.61109 8.76316 6.00215C9 6.18247 9.13158 6.07943 9.31579 5.95063C11.0789 4.61109 12.8421 3.2458 14.6053 1.90626C14.6842 1.82898 14.7632 1.7517 14.9211 1.6229C10.9474 1.6229 7.07895 1.6229 3.21053 1.6229Z"
                          fill="#185D9E"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="emailId"
                      className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Email Id"
                      {...register("emailId", {
                        required: "Email is required",
                        pattern: {
                          value: EMAIL_ONLY,
                          message: "Invalid Email Address",
                        },
                      })}
                    />
                  </div>
                  {errors.emailId && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.emailId?.message}
                    </p>
                  )}
                </div>
                <div className="text-right mt-5 mb-8">
                  <button
                    onClick={() => setShowForgotPass(false)}
                    className="text-sm text-[#007AC8] font-bold"
                  >
                    Back to Login
                  </button>
                </div>
                <button
                  disabled={isForgotPassLoading}
                  type="Submit"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "30px auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="font-bold disabled:opacity-25 text-[14px] bg-[#007AC8] w-full py-4 rounded-lg text-white"
                >
                  <div style={{ mr: 1 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      fill="white"
                      height="18"
                      viewBox="0 0 512 512"
                    >
                      <path d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
                    </svg>
                  </div>
                  Continue
                </button>
              </form>
            </>
          )}

          <div className="mt-8 bg-slate-300 p-6 rounded-md">
            <p className="text-[12px]">
              For any queries please click the <strong>User Guide</strong>,
              Contact <strong className="text-[#007AC8]">8925965077</strong> or{" "}
              <strong className="text-[#007AC8]">it@spacedraft.in</strong> from
              9AM to 6PM
            </p>
            <p className="text-right">
              <button className="bg-[#355d76] text-white text-[12px] py-1 px-3">
                User Guide
              </button>
            </p>
          </div>
        </div>

        <p className="text-[12px] text-center my-5">
          &copy; All rights reserved 2025 Spacedraft India Pvt, Ltd.
        </p>
      </div>
    </div>
  );
};

export default Login;
