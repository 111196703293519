import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getPurchaseList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.purchase}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addPurchase = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.purchase}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editPurchase = async (poObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.purchase}/${poObj.id}`,
    poObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deletePurchase = async (poId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.purchase}/${poId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};


export const getPurchaseById = async (id) => {
  try {
    const { data: response } = await axiosInstance.get(`${endPoints.purchase}/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};


export const purchaseApproval = async (poObj) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.purchase}/sub/approval/${poObj.id}`,
    poObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
