// import Cookies from "js-cookie";
// import axios from "axios";

// const baseURL = "http://localhost:8080";



// export const getToken = () =>
//   Cookies.get("authToken") ? Cookies.get("authToken") : null;

// export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

// export const axiosInstance = axios.create({
//   baseURL,
//   headers: { Authorization: getAuthorizationHeader() },
// });

export const staticMessages = {
  INVLID_EMAIL_PASS: "Invalid email address or password",
  UNAUTHERISED_USER: "User Not Created, which has Unautherized!",
  USER_ADDED_SUCCESS: "USer Has been Added Successfully!",
  USER_DELETED_SUCCESS: "USer Has been Deleted Successfully!",
};

export const fright_terms = [
  "Freight Allowed",
  "Freight Collect",
  "Freight Prepaid & Add",
  "Not Applicable",
  "Included",
];
export const payment_terms = [
  "Local Purchase",
  "Net 30",
  "1/4% 10 Net 30",
  "1/2% 10 Net 30",
  "3/4% 10 Net 30",
  "3/4% 10 Net 60",
  "3/4% 30 Net 60",
  "1% 10 Net 30",
  "1% 10 Net 60",
  "1% 30 Net 60",
  "Net 45",
  "Net 60",
  "2% 10 Net 30",
  "2% 10 Net 60",
  "2% 10 Net 90",
  "2% 30 Net 60",
  "2% 30 Net 90",
  "Net 90",
  "15 Days Credit",
  "20 Days Credit",
  "30 Days Credit",
  "15 Days PDC",
  "30 Days PDC",
  "100% Advance",
  "Immiedate",
  "50% Advance ",
  "30% Advance",
  "75% Advance",
  "100% Payment Against delivery Including GST",
  "50%Aft delivery &50%aft completion",
  "07 Days Credit",
  "25%Advance",
  "7 Days Credit ",
  "25 Days Credit",
  "50 % Advance along with PO ",
  "50% payment advance along with PO",
  "50% advance with  order   bal 15 Days  after  finishing of the job",
  "75% advance with PO , Balance 25% after job completion.",
  "50% Advance &  balance 50% 7 days Credit",
  "50% Advance &  50% after work Completion",
  "30% Advance along with PO, Bal 70% 45 Days Credit",
  "30 Days PDC",
  "50% Advance , Balance 50% 30 Days PDC",
  "50% Advance , Balance 50% 15 Days Credit",
  "50% advance & after 10 or 15 days full payment",
  "50% Advance & Bal 50% 30 Days credit",
  "50% Advance & Balance 50% Before Delivery",
  "20% Advance Balance 80% 30 Days Credit",
  "100% Advance Including GST",
  "50% Advance with PO , Balance 50% 30 Days PDC",
  "50% Advance Excluding GST, Balance 50% 30 Days PDC against delivery.",
  "30 Days PDC from the date of delivery",
  "50% Advance , Balance 50% After completion of Audit",
  "50% Advance with PO, Balance 50% against delivery.",
  "100% Against Completion of work Including GST Certified by Project Team.",
  "50% Advance including GST, Balance 50% 30 Days PDC at delivery",
  "15 Days PDC Against Delivery",
  "30 Days Credit Against Delivery",
  "30 Days PDC at the Date of Delivery",
  "30 Days PDC at delivery ",
  "50% Advance, Balance 50% 30 days PDC",
  "50% Advance along with Po, Balance 50% Against Material Readinees & Before Dispatch",
  "100% Against completed of work",
  "50% Advance & Balance 50% 30 Days credit",
  "100% Advance along with PO",
  "45 Days PDC",
  "50% Advance, 25% on Frame Fixing completion, balance 25% on within 30 Days",
  "50% Advance,Balance 50% Against Completion of  Work Cretified From Project",
  "100% Advance Including GST",
  "50% Advance,Balance 50% Upon Completion of Job Cretified From Project",
  "100% delivery against payment ",
  "100% Payment Against completion of delivery along with including GST",
  "100% Advance Including GST",
  "100% Advance along with PO Including GST",
  "70% Advance along with LPO, Bal 30% against after installation certified from project",
  "75% advance for material Purchase and 25% after completion of work with Project Certification",
  "50% Advance, 25% on Frame Fixing completion, balance 25% on within 30 Days",
];

export const payment_mode = ["NEFT", "CASH", "CHEQUE", "CREDIT"];

