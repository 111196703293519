import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";



export const download = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.emailTemplates}/sub/download`,
    reqBody,
    {
      responseType: 'arraybuffer',
      headers: { Authorization: getAuthorizationHeader(), 'Accept': 'application/pdf' },
    }
  );
  return response;
};

