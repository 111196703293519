import React, { useState, useEffect } from "react";
import { Switch, Cascader, Checkbox } from "antd";

export default function ModulePermission(props) {

    const arrOptions = [
        { label: "Read", value: "read", defaultChecked: true },
        { label: "Write", value: "write", defaultChecked: true },
        { label: "Update", value: "update", defaultChecked: true },
        { label: "Delete", value: "delete", defaultChecked: true },
        { label: "Upload", value: "upload", defaultChecked: true },
        { label: "Download", value: "download", defaultChecked: true },
    ]
    const [options, setOptions] = useState(arrOptions);
    const updateOptions = (data) => {
        setOptions((prevState) => {
            return [...data];
        })
    }
    const { module, moduleList, modueName, isEdit, isView } = props;
    const { SHOW_CHILD } = Cascader;
    const [disableCascade, setDisableCascade] = useState(false);
    const [disablePublicAccess, setDisablePublicAccess] = useState(false);
    const [isPublicAccess, setIsPublicAccess] = useState(module.public_access);
    const [checkedValues, setCheckedValues] = useState([["read"], ["write"], ["update"], ["delete"], ["upload"], ["download"]].filter(permission => module[permission] === true ? [permission] : null));
    const [isSwichChecked, setIsSwichChecked] = useState(false);

    const onSwitchChange = (checked) => {
        setIsSwichChecked(checked);
        setDisableCascade(!checked);
        setDisablePublicAccess(!checked);
        setIsPublicAccess(checked);
        setCheckedValues(checked ? [["read"], ["write"], ["update"], ["delete"], ["upload"], ["download"]] : []);
        if (module.moduleName === modueName) {
            module.public_access = checked;
            module.delete = checked;
            module.read = checked;
            module.update = checked;
            module.write = checked;
            module.download = checked;
            module.upload = checked;
        }

        if (checked) {
            setOptions(arrOptions);
        }
    };


    const unCheckPermissionValues = (key) => {
        let tmpOptions = [];
        for (let i in arrOptions) {
            let option = arrOptions[i];
            if (key == 'read') {
                if (option.value != 'read') {
                    option.disabled = true;
                } else {
                    option.disabled = false;
                }
            }
            if (key == 'write') {
                if (option.value != 'read' && option.value != 'download' && option.value != 'write') {
                    option.disabled = true;
                } else {
                    option.disabled = false;
                }
            }
            if (key == 'update') {
                if (option.value != 'read' && option.value != 'download' && option.value != 'write' && option.value != 'update' && option.value != 'upload') {
                    option.disabled = true;
                } else {
                    option.disabled = false;
                }
            }
            tmpOptions.push(option);
        }
        updateOptions(tmpOptions);
    }

    const checkPermissionValues = (key) => {
        let tmpOptions = [];
        for (let i in arrOptions) {
            let option = arrOptions[i];
            if (key == 'update') {
                option.disabled = false;
            }
            tmpOptions.push(option);
        }
        updateOptions(tmpOptions);
    }

    const onCascaderChange = (value) => {
        const flatValues = value.flat();
        setCheckedValues(value);
        console.log(flatValues);
        if (flatValues.length <= 0 || !flatValues.includes('read')) {
            setCheckedValues([]);
            unCheckPermissionValues('read');
        }
        if (flatValues.includes('read')) {
            if (module.moduleName === modueName) {
                module.public_access = true;
            }
            if (!flatValues.includes('write')) {
                let values = [['read']];
                if (flatValues.includes('download')) {
                    values.push(['download']);
                }
                setCheckedValues(values);
                unCheckPermissionValues('write');
            }
            if (flatValues.includes('write')) {
                if (!flatValues.includes('update')) {
                    let values = [['read'], ['write']];
                    if (flatValues.includes('download')) {
                        values.push(['download']);
                    }
                    if (flatValues.includes('upload')) {
                        values.push(['upload']);
                    }
                    setCheckedValues(values);
                    unCheckPermissionValues('update');
                }
            }
        }
        if (flatValues.length == 3 && flatValues.includes('read') && flatValues.includes('write') && flatValues.includes('update')) {
            setCheckedValues([['read'], ['write'], ['update']]);
            checkPermissionValues('update');
        }
        if (flatValues.length == 4 && flatValues.includes('download') && flatValues.includes('read') && flatValues.includes('write') && flatValues.includes('update')) {
            setCheckedValues([['read'], ['write'], ['update'], ['download']]);
            checkPermissionValues('update');
        }
        if (module.moduleName === modueName) {
            module.read = flatValues.includes('read') ? true : false;
            module.write = flatValues.includes('write') ? true : false;
            module.update = flatValues.includes('update') ? true : false;
            module.download = flatValues.includes('download') ? true : false;
            module.upload = flatValues.includes('upload') ? true : false;
            module.delete = flatValues.includes('delete') ? true : false;
            module.approval = flatValues.includes('approval') ? true : false;
        }
    };

    const onPublicChange = (e) => {
        if (e.target.checked !== true && module.moduleName === modueName) {
            module.public_access = false;
        }
        setIsPublicAccess(e.target.checked);
    };


    useEffect(() => {
        if (!module.read && !module.write && !module.update && !module.delete) {
            setDisableCascade(true);
            setDisablePublicAccess(true);
        } else {
            setIsSwichChecked(true);
        }
    }, []);

    return (
        <div className="flex items-center mb-2">
            <label
                className="text-[#2B384A] text-[12px] block mb-2 w-[30%] font-bold"
                htmlFor={module.moduleName}
            >
                {module.moduleName}
            </label>

            <div className="permission">
                <Switch
                    id={module.moduleName}
                    size="small"
                    checked={isSwichChecked}
                    onChange={onSwitchChange}
                    disabled={isView}
                />
                <Cascader
                    options={options}
                    disabled={isView ?? disableCascade}
                    onChange={(field) => {
                        onCascaderChange(field);
                        // Do something when a checkbox is checked or unchecked
                        console.log('Checkbox selection changed:', field);
                    }}
                    multiple
                    showCheckedStrategy={SHOW_CHILD}
                    maxTagCount="responsive"
                    value={checkedValues}
                    className="profile-cascader"
                />
                <Checkbox
                    className="ml-5"
                    disabled={isView ?? disablePublicAccess}
                    checked={isPublicAccess}
                    onChange={onPublicChange}
                >
                    Public Access
                </Checkbox>
            </div>
        </div>

    );
}
