import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getUserList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.users}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addUser = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.users}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editUser = async (userObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.users}/${userObj.id}`,
    userObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteUser = async (userId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.users}/${userId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const resetPassword = async (resetObj) => {
  console.log(resetObj, 'resetObj');
  const { data: response } = await axiosInstance.put(
    `${endPoints.users}/sub/resetpass`,
    { 'password': resetObj.password },
    {
      headers: { Authorization: `Bearer ${resetObj.token}` },
    }
  );
  return response;
}

export const uploadFilesUsers = async (imgObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.users}/sub/upload/${imgObj?.id}`,
    imgObj?.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const changePassword = async (imgObj) => {
  console.log(imgObj, 'imgObj');
  const { data: response } = await axiosInstance.put(
    `${endPoints.users}/sub/changepass`,
    {
      'oldpassword': imgObj.oldpassword,
      'password': imgObj.password
    },
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
}

export const getUserData = async (userid) => {
  const { data: response } = await axiosInstance.get(
    `${endPoints.users}/${userid}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};