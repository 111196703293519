import React from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm, FormProvider } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getCategoryList } from "../../queries/Categories";
import { useQuery } from "@tanstack/react-query";
import SupplierForm from "./SupplierForm";
import ContractorForm from "./ContractorForm";

export default function AddVendor(props) {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const {
        vendor,
        isLoading,
        editingVendr,
        isEdit,
        isView,
        closeModel,
        adVendor,
    } = props;
    const closingModel = () => {
        closeModel();
    };
    const { data } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategoryList,
    });

    const methods = useForm(
        isEdit || isView ? {
            defaultValues: {
                type: vendor?.type,
                name: vendor?.name,
                ...(vendor.type === 'Supplier' ? {
                address: vendor.address,
                account_details: {
                    bank_name: vendor?.account_details?.bank_name,
                    branch_name: vendor?.account_details?.branch_name,
                    bank_account_no: vendor?.account_details?.bank_account_no,
                    type_of_account: vendor?.account_details?.type_of_account,
                    ifsc_code: vendor?.account_details?.ifsc_code,
                    pan_no: vendor?.account_details?.pan_no,
                },
                contact_person_a: {
                    name: vendor?.contact_person_a?.name,
                    designation: vendor?.contact_person_a?.designation,
                    phone_num: vendor?.contact_person_a?.phone_num,
                    email: vendor?.contact_person_a?.email,
                },
                contact_person_b: {
                    name: vendor?.contact_person_b?.name,
                    designation: vendor?.contact_person_b?.designation,
                    phone_num: vendor?.contact_person_b?.phone_num,
                    email: vendor?.contact_person_b?.email,
                },
                vendor_code: vendor?.vendor_code,
                category: vendor?.category,
                website: vendor?.website,
                weekly_off: vendor?.weekly_off,
                working_address: vendor?.working_address,
                status_of_the_company: vendor?.status_of_the_company,
                type_of_the_company: vendor?.type_of_the_company,
                attachments: vendor?.attachments,
                gst: vendor?.gst,
                } : {
                    address: vendor.address,
                    type_of_contractor: vendor.type_of_contractor,
                    experience_in_field: vendor.experience_in_field,
                    project_completed_count: vendor.project_completed_count,
                    account_details: {
                        bank_name: vendor?.account_details?.bank_name,
                        branch_name: vendor?.account_details?.branch_name,
                        bank_account_no: vendor?.account_details?.bank_account_no,
                        type_of_account: vendor?.account_details?.type_of_account,
                        ifsc_code: vendor?.account_details?.ifsc_code,
                        pan_no: vendor?.account_details?.pan_no,
                    },
                    aadhaar: vendor.aadhaar,
                    gst: vendor.gst,
                    contact_person_a: {
                        name: vendor?.contact_person_a?.name,
                        designation: vendor?.contact_person_a?.designation,
                        phone_num: vendor?.contact_person_a?.phone_num,
                        email: vendor?.contact_person_a?.email,
                    },
                    contact_person_b: {
                        name: vendor?.contact_person_b?.name,
                        designation: vendor?.contact_person_b?.designation,
                        phone_num: vendor?.contact_person_b?.phone_num,
                        email: vendor?.contact_person_b?.email,
                    },
                    attachments: vendor?.attachments,
                })
            },
            }
            : {
            defaultValues: {
                type: "",
                name: "",
                ...(vendor.type === 'Supplier' ? {
                address: "",
                account_details: {
                    bank_name: "",
                    branch_name: "",
                    bank_account_no: "",
                    type_of_account: "",
                    ifsc_code: "",
                    pan_no: "",
                },
                contact_person_a: {
                    name: "",
                    designation: "",
                    phone_num: "",
                    email: "",
                },
                contact_person_b: {
                    name: "",
                    designation: "",
                    phone_num: "",
                    email: "",
                },
                vendor_code: "",
                category: "",
                website: "",
                weekly_off: "",
                working_address: "",
                status_of_the_company: "",
                type_of_the_company: "",
                attachments: [],
                gst: "",
                } : {
                    address: "",
                    type_of_contractor: "",
                    experience_in_field: "",
                    project_completed_count: "",
                    account_details: {
                        bank_name: "",
                        branch_name: "",
                        bank_account_no: "",
                        type_of_account: "",
                        ifsc_code: "",
                        pan_no: "",
                    },
                    aadhaar: "",
                    gst: "",
                    contact_person_a: {
                        name: "",
                        designation: "",
                        phone_num: "",
                        email: "",
                    },
                    contact_person_b: {
                        name: "",
                        designation: "",
                        phone_num: "",
                        email: "",
                    },
                    attachments: "",
                })
            },
            }
    );
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
        watch,
    } = methods;

    const selectedType = watch("type");

    const onSubmit = (data) => {
        if (isEdit) {
          const payload = {
            id: vendor._id,
            body: {
                ...data,
                org_id: userData.org_id,
            },
        };
        editingVendr(payload);
        closingModel();
        }else{
          const payload = {
            ...data,
            org_id: userData.org_id,
        };
        adVendor(payload);
        closingModel();
        }
        // const payload = {
        //     ...data,
        //     org_id: userData.org_id,
        // };
        // adVendor(payload);
        // closingModel();
        // console.log(data);
        reset();
    };

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit
                            ? "Edit Supplier"
                            : isView
                            ? "View Supplier"
                            : "Add New Supplier"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Enter Supplier details and add to list
                        {isEdit
                            ? " or update Supplier details"
                            : isView
                            ? " or view Supplier details"
                            : ""}
                    </p>
                </div>

                <div className="w-[60%]">
                    <FormProvider {...methods}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={isView ? "view-page" : ""}
                        >
                            <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                                <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                    Supplier Details
                                </h4>

                                <div className="grid grid-cols-2 gap-8 text-left">
                                    <div>
                                        <div>
                                            <label
                                                className="text-[#2B384A] text-[12px] block mb-2"
                                                htmlFor=""
                                            >
                                                Name Of The Company{" "}
                                                <span className="text-[red]">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className={
                                                    errors.name
                                                        ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                                        : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                                }
                                                type="text"
                                                {...register("name", {
                                                    required:
                                                        "Supplier Company Name is required",
                                                })}
                                            />
                                        </div>
                                        {errors.name && (
                                            <p
                                                className="text-xs text-[red] mt-1"
                                                role="alert"
                                            >
                                                {errors.name?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            <label
                                                className="text-[#2B384A] text-[12px] block mb-2"
                                                htmlFor=""
                                            >
                                                Supplier Type{" "}
                                                <span className="text-[red]">
                                                    *
                                                </span>
                                            </label>
                                            <select
                                                className="border border-gray-300 text-[#2D3846] text-[16px] rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 font-bold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                {...register("type", {
                                                    required:
                                                        "Supplier Type is required",
                                                })}
                                            >
                                                <option value="">
                                                    Select Supplier Type
                                                </option>
                                                <option value="Supplier">
                                                    Supplier
                                                </option>
                                                <option value="Contractor">
                                                    Contractor
                                                </option>
                                            </select>
                                        </div>
                                        {errors.type && (
                                            <p
                                                className="text-xs text-[red] mt-1"
                                                role="alert"
                                            >
                                                {errors.type?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {selectedType === "Supplier" && (
                                    <SupplierForm
                                        isEdit={isEdit}
                                        isView={isView}
                                        vendor={vendor}
                                    />
                                )}

                                {selectedType === "Contractor" && (
                                    <ContractorForm isEdit={isEdit}
                                    isView={isView}
                                    vendor={vendor} />
                                )}
                            </div>
                            {!isView && (
                                <p className="text-right mt-8 flex items-center justify-end">
                                    <button
                                        onClick={closingModel}
                                        className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="Submit"
                                        className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <BtnLoader />
                                        ) : isEdit ? (
                                            "Update"
                                        ) : (
                                            "Create"
                                        )}
                                    </button>
                                </p>
                            )}
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    );
}
