import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../components/utils/Loader";
import { useQueryMutation } from "../../hooks/useMutation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getCategoryList } from "../../queries/Categories";
import { Table } from "antd";
import useModulePermissions from "../../hooks/useModulePermissions";
import { MaterialColumns } from "../../utils/colConfig";
import { useFilteredList } from "../../hooks/useFilteredList";
import SearchList from "../../components/SearchList";
import { getStockList } from "../../queries/Stocks";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.name.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function StockList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [showImportModel, setImportModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [materialData, setMaterialData] = useState({});
    const { isLoading, isError, data } = useQuery({
        queryKey: ["stocks"],
        queryFn: getStockList,
    });


    const {
        filteredList: materialList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);



    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };


  const settingIsEdit = (editVisible, material) => {
    setShowModel(true);
    setMaterialData(material);
    setIsEdit(editVisible);
};
    


    

    const closeImportMatModel = () => {
        setImportModel(false);
    };



    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Material Name..."}
                        />
                        <div className="flex">
                        {permissions.showMaterialMenu !== undefined &&
                            permissions.showMaterialMenu.write && (
                                <>
                                    <button
                                        onClick={() => setShowModel(true)}
                                        className="primary mr-3 btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                    >
                                        <img src={whitePlus} alt="" />
                                        <span className="ml-3">
                                            Add New Material
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => setImportModel(true)}
                                        className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                    >
                                        <img src={whitePlus} alt="" />
                                        <span className="ml-3">
                                            Import Materials
                                        </span>
                                    </button>
                                </>
                            )}
                            </div>
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={data}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setMaterialData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Material here
                    </p>
                    {permissions.showMaterialMenu !== undefined &&
                        permissions.showMaterialMenu.write && (
                            <p className="flex justify-center">
                                <button
                                    onClick={() => setShowModel(true)}
                                    className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                >
                                    <img src={whitePlus} alt="" />
                                    <span className="ml-3">
                                        Add New Material
                                    </span>
                                </button>
                            </p>
                        )}
                </div>
            )}

        </div>
    );
}
