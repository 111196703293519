import React, { useState } from "react";
import cameraIcon from '../assets/image/camera-icon.svg';
import cameraIconWhite from '../assets/image/camera-icon-white.svg';
import toast from "react-hot-toast";
import deleteIcon from '../assets/image/delete-white.svg';
import attachIcon from '../assets/image/attachment-white.svg';
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getDepartmentList } from "../queries/Departments";
import { getUserProfileList } from "../queries/UserProfiles";
import { getUserData } from "../queries/User";
import { getRoleList } from "../queries/Roles";
import find from 'lodash/find';
import { uploadFilesUsers, changePassword } from "../queries/User";

export default function MyProfile() {
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
 
  console.log(userData)
  console.log(userData._id)
  const [department, setDepartment] = useState(userData.department);
  const [profile, setProfile] = useState(userData.profile_id);
  const [role, setRole] = useState(userData.role_id);
  
  const { data } = useQuery({
    queryKey: ["departments"],
    queryFn: getDepartmentList,
  });
  const { data: profileData } = useQuery({
    queryKey: ["profiles"],
    queryFn: getUserProfileList,
  });
  const { data: roleData } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoleList,
  });
 const { data: UserDetails } = useQuery({
    queryKey: ["users"],
    queryFn: ()=> getUserData(userData._id),
  });
    const [previewUrl, setPreviewUrl] = useState(UserDetails?.result?.profile_url?.url ||cameraIcon);

  const handleFileUpload = (e) => {
    const file = e.target.files && e.target.files[0]; // Check if files exist;
    if (file) {
      setCurrentFile(file);
    } else {
      toast.error('upload only 1 file.');
      setCurrentFile();
      return;
    }
  };

  const deleteFile = (file) => {
    const newFiles = currentFile.filter((f) => f !== file);
    setCurrentFile(newFiles);
  }

  const uploadProfileImg = () => {
    // e.preventDefault();
    const formData = new FormData();
    formData.append("file", currentFile);
    const payload = {
      id: userData._id,
      body: formData,
    };
    uploadFilesUsers(payload).then((res) => {
if (res?.status === "success") {
  toast.success(res?.message);
  setShowImageUploadModal(false)
}
    }).catch(err => {
      console.log(err);
    });
  };

  const {
    register,
    formState: { errors },
  } = useForm(
    {
      defaultValues: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        mobile: userData.mobile,
        emp_code: userData.emp_code,
        department: userData.department,
        gender: userData.gender,
        profile_id: userData.profile_id,
        role_id: userData.role_id,
        profile_url:userData.profile_url
      }
    }
  );

  // password starts

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePwdModal, setShowChangePwdModal] = useState(false);

  const onSubmitChangePassword = (e) => {
    e.preventDefault();
    if (!currentPassword) {
      toast.error('Current password is required');
      return false;
    } else if (!password) {
      toast.error('New password is required');
      return false;
    } else if (!confirmPassword) {
      toast.error('Confirm password is required');
      return false;
    } else if (password !== confirmPassword) {
      toast.error('Password & Confirm password is mis-matched');
      return false;
    }
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!regex.test(password)) {
      toast.error('Password must be at least 8 characters long and contain an uppercase letter, a number, and a special character');
      return false;
    }

    const chngPwd = {
      password: password,
      oldpassword: currentPassword
    }
    if (password !== '' && confirmPassword !== '' && password === confirmPassword) {
      changePassword(chngPwd).then((res) => {
        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
        setShowChangePwdModal(false);
        toast.success("Password Changed");
      }).catch(err => {
        console.log(err);
      });
    } else {
      toast.error('Please enter your password with valid format and match with Confirm Password');
      return false;
    }
  }

  // password end

  const getValue = (data, id) => {
    let obj = find(data, { _id: id });
    if (obj) { return obj.name; } else { return "-"; }
  }

  return (
    <>
      <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">My Profile</h3>
      <div className="relative w-[100px] h-[100px]">
        <img src={previewUrl} alt="" className="p-[10px] w-[100px] h-[100px] bg-white rounded-full" />
        <span class="bottom-0 right-0 absolute cursor-pointer w-[30px] h-[30px] bg-[#85ceff] border-2 border-[#fff] rounded-full" onClick={() => setShowImageUploadModal(true)}>
          <img src={cameraIconWhite} alt="" className="ml-[3px] mt-[3px] w-[20px] h-[20px]" />
        </span>
      </div>

      <div class="flex mt-[20px]">
        {/* Personal Information Start */}
        <div class="w-[69%] p-2 mr-[2%] bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-4">
          <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-4">
            Personal Information
          </h4>

          <div className="grid grid-cols-3 gap-4 text-left">
            <div>
              <div>
                <label className="text-[#2B384A] text-[12px] block mb-2" htmlFor="">First Name</label>
                <input
                  className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  type="text"
                  disabled
                  {...register("first_name")}
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  Last Name
                </label>
                <input
                  className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  type="text"
                  disabled
                  {...register("last_name")}
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  Email
                </label>
                <input className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]' type="text" disabled
                  {...register("email")} />
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  Mobile
                </label>
                <input
                  className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  disabled
                  type="text"
                  {...register("mobile")}
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  Employee Code
                </label>
                <input className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  type="text"
                  disabled
                  {...register("emp_code")} />
              </div>
            </div>
            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  Department
                </label>
                <input className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  type="text"
                  disabled
                  value={getValue(data?.result, department)}
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  User Profile
                </label>
                <input className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  type="text"
                  disabled
                  value={getValue(profileData?.result, profile)}
                />
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-[#2B384A] text-[12px] block mb-2"
                  htmlFor=""
                >
                  Role
                </label>
                <input className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                  type="text"
                  disabled
                  value={getValue(roleData?.result, role)}
                />
              </div>
            </div>

            <div>
              <label
                className="text-[#2B384A] text-[12px] block mb-2"
                htmlFor=""
              >
                Gender
              </label>
              <input className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                type="text"
                disabled
                value={getValue([{ _id: "male", name: "Male" }, { _id: "female", name: "Female" }, { _id: "transgender", name: "Transgender" }], userData.gender)}
              />
            </div>

          </div>
          <p className="text-right mt-8 flex justify-start">
            <button
              onClick={() => setShowChangePwdModal(true)}
              className="text-[#007AC8] text-[14px] font-bold uppercase"
            >
              Change Password
            </button>
          </p>
        </div>
        {/* Personal Information End */}

      </div>

      {/* Change Password Start */}
      {showChangePwdModal && <div
        id="billing-modal"
        tabindex="-1"
        aria-hidden="true"
        className="flex overflow-y-auto overflow-x-hidden bg-opacity-60 bg-black fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full"
      >

        <div className="relative p-4 w-full max-w-lg max-h-full">
          <div className="relative bg-white rounded-lg shadow">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-900">
                Change Password
              </h3>
              <button
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
                onClick={() => setShowChangePwdModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div class="p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-4 dark:bg-gray-800 dark:border-gray-700">
              <form>
                <div className="text-left mb-4">
                  <label
                    htmlFor="email"
                    className="text-[#2B384A] text-[12px] block mb-2"
                  >
                    Current Password <span className="text-[red]">*</span>
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="6.75"
                          width="14.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      type="password"
                      id="currentPassword"
                      className="pl-14 text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      placeholder="Enter Password"
                      {...register("currentPassword")}
                      autoComplete="off"
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      value={currentPassword}
                    />
                  </div>
                  {errors.currentPassword && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.currentPassword?.message}
                    </p>
                  )}
                </div>
                <div className="text-left mb-4">
                  <label
                    htmlFor=""
                    className="text-[#2B384A] text-[12px] block mb-2"
                  >
                    New Password <span className="text-[red]">*</span>
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="6.75"
                          width="14.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      type="password"
                      id="password"
                      className="pl-14 text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      placeholder="Enter Password"
                      {...register("password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="text-left mb-5">
                  <label
                    htmlFor="email"
                    className="text-[#2B384A] text-[12px] block mb-2"
                  >
                    Confirm New Password <span className="text-[red]">*</span>
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="6.75"
                          width="14.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      type="password"
                      id="confirmPassword"
                      className="pl-14 text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      placeholder="Enter Password"
                      {...register("confirmPassword", {
                        required: "Confirm password is required",
                        validate: {
                          match: (value) => value === password || "Passwords do not match",  // Custom validation to check if passwords match
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </div>
                </div>

                <p className="text-right mt-8 flex items-center justify-end">
                  <button
                    onClick={() => setShowChangePwdModal(false)}
                    className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={onSubmitChangePassword}
                    className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                  >
                    Save
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      }
      {/* Change Password End */}

      {showImageUploadModal && <>
        <div
          id="billing-modal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden bg-opacity-60 bg-black fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full"
        >
          <div className="relative p-4 w-full max-w-lg max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Upload Image
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="authentication-modal"
                  onClick={() => setShowImageUploadModal(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <div className="mt-3">
                  <input
                    type="file"
                    id="fileUpload"
                    multiple
                    onChange={handleFileUpload}
                  />
                  <div className="mt-5">
                    {currentFile?.length > 0 && (
                      <>
                        <ul>
                          {currentFile?.map((file, index) => (
                            <div key={index} className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between">
                              <p className="flex items-center text-[13px] text-gray-300">
                                <span className="pr-2"><img src={attachIcon} alt="" /></span>
                                {file?.file_name || file?.name}
                              </p>
                              <span onClick={() => deleteFile(file)} className="cursor-pointer"><img src={deleteIcon} alt="" /></span>
                            </div>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div class="text-right md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={uploadProfileImg}
                  className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                >Upload</button>
              </div>
            </div>
          </div>
        </div>
      </>}

    </>
  )
}
