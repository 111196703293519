import Login from './container/Login';
import Main from './container/Main';
import SettingsMain from './container/SettingsMain';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  useRoutes,
  Navigate
} from "react-router-dom";
import './App.css';
import Dashboard from './container/Dashboard';
import MaterialsMain from './container/MaterialsMain';
import ProjectsMain from './container/ProjectsMain';
import CustomersMain from './container/CustomersMain';
import IndentMain from './container/IndentMain';
import PurchaseMain from './container/PurchaseOrderMain';
import OrganizationMain from './container/OrganizationMain';
import AddIndent from './container/indent/AddIndent';
import ApproveIndent from './container/indent/ApproveIndent';
import NotificationList from './components/NotificationList';
import { HelmetProvider } from 'react-helmet-async';
import ApprovePurchase from './container/purchase/ApprovePurchase';
import ApproveGrn from './container/grn/ApproveGrn';
import ForgotPassword from './container/ForgotPassword';
import MyProfile from './container/MyProfile';

const queryClient = new QueryClient();

function App() {
  const RouteApp = () => {
    
    const entities = Cookies.get('authToken')
    let routes = useRoutes([
      { 
        path: "/", 
        element: entities ? <Main /> : <Login />,
        children: [
          {
            path: "/settings",
            element: <SettingsMain />,
          },
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/materials",
            element: <MaterialsMain />,
          },
          {
            path: "/customers",
            element: <CustomersMain />,
          },
          {
            path: "/projects",
            element: <ProjectsMain />,
          },
          {
            path: "/indent",
            element: <IndentMain />,
          },
          {
            path: "/indent/:id",
            element: <ApproveIndent />,
          },
          {
            path: "/purchase",
            element: <PurchaseMain />,
          },
          {
            path: "/purchaseorder/:id",
            element: <ApprovePurchase />,
          },
          {
            path: "/grn/:id",
            element: <ApproveGrn />,
          },
          {
            path: "/organization",
            element: <OrganizationMain />,
          },
          {
            path: "/notification",
            element: <NotificationList />,
          },
          {
            path: "/myprofile",
            element: <MyProfile />,
          },
        ],
      },
      { 
        path: "/login", 
        element: entities ? <Navigate to="/" /> : <Login />
      },
      { 
        path: "/forgotpassword/:token", 
        element: <ForgotPassword />
      },
      
    ]);
    return routes;
  }
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Router>
          <RouteApp />
        </Router>
        <Toaster position="top-right" limit={1} toastOptions={{
            success: {
              style: {
                padding: '10px',
                color: '#fff',
                borderRadius: '20px',
                background: '#42BB7A',
                fontSize: '13px'
              },
              iconTheme: {
                primary: '#42BB7A',
                secondary: '#fff',
              }
            },
            error: {
              style: {
                padding: '10px',
                color: '#fff',
                borderRadius: '20px',
                background: '#F47474',
                fontSize: '13px'
              },
              iconTheme: {
                primary: '#F47474',
                secondary: '#fff',
              }
            },
        }} />
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </HelmetProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
