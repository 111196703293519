import React, { useEffect, useState, useRef } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import ModulePermission from "./ModulePermission";
import { getUserProfileList } from "../../queries/UserProfiles";
import { useQuery } from "@tanstack/react-query";

export default function AddProfile(props) {
    const userName = JSON.parse(localStorage.getItem("loggedUser"));
    const {
        isEdit,
        isLoading,
        profile,
        edProfile,
        adProfile,
        closeModel,
        isView,
    } = props;
    const [modList, setModList] = useState(isEdit || isView ? profile.modulePermissions : []);
    const [permissionData, setPermissionData] = useState([]);

    console.log(modList)
    
    const { data:profileData } = useQuery({
        queryKey: ["profiles"],
        queryFn: getUserProfileList,
      });

    const closingModel = () => {
        props.closeModel();
    };
    const {
        register,
        reset,
        control,
        clearErrors,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm(
        isEdit || isView
            ? {
                defaultValues: {
                    name: profile.name,
                    description: profile.description,
                },
            }
            : {
                defaultValues: {
                    name: "",
                    description: "",
                },
            }
    );

    const onSubmit = (data) => {
        if(isEdit){
            const reqBody = {
                id: profile._id,
                body: {
                    name: data.name,
                    description: data.description,
                    modulePermissions: modList,
                    org_id: profile.org_id,
                },
            };
            //console.log(reqBody)
            edProfile(reqBody);
            closingModel();
        }else{
            const reqBody = {
                name: data.name,
                description: data.description,
                modulePermissions: modList,
                org_id: userName.org_id,
            };
            //console.log(reqBody)
            adProfile(reqBody);
            closingModel();
        }
    };

    const moduleList = (module) => {
        setModList((prevModList) => [...prevModList, module]);
    };

    useEffect(() => {
        if(!isEdit && !isView){
            const modulePermissionOff = profileData?.result[0]?.modulePermissions.map(p => {
                return {
                    ...p,
                    public_access: false,
                    read: false,
                    write: false,
                    update: false,
                    delete: false,
                    upload: false,
                    download: false,
                }
            })
            if (profileData?.result) {
                setModList(modulePermissionOff);
            }
        }
        
    }, [profileData])

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit ? "Edit Profile" : isView ?  "View Profile" : "Add New Profile"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Enter Profile details and add to list
                        {isEdit ? " or update profile details" : isView ? " or view profile details" : ""}
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Profile List
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Profile Name <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Please Add Profile Name",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Description
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("description")}
                                        />
                                    </div>
                                </div>
                            </div>
                            {watch("name") && watch("name").length > 0 && (
                              <>
                              <div class="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between mt-8">
                                <h4 class="text-[20px] text-left">
                                    Module Permission
                                </h4>
                            </div>
                            <div className="mt-5">
                                {console.log(modList)}
                                {modList?.map((module) => (
                                    <ModulePermission
                                        modueName={module.moduleName}
                                        module={module}
                                        moduleList={moduleList}
                                        key={module._id}
                                        isEdit={isEdit}
                                        isView={isView}
                                    />
                                ))}
                            </div>
                              </>
                            )}
                            
                        </div>
                        {!isView && (
                            <p className="text-right mt-8 flex items-center justify-end">
                                <button
                                    onClick={closingModel}
                                    className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="Submit"
                                    className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
                                </button>
                            </p>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
