import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from "js-cookie";

//export const baseURL = "http://localhost:8080";
export const baseURL = "http://cosmo.spacedraft.in:8080";

export const endPoints = {
    login: "/auth/login",
    forgotpass: "/auth/forgotpass",
    users: "/v1/users",
    departments: "/v1/department",
    categories: "/v1/category",
    materials: "/v1/materials",
    projects: "/v1/project",
    vendors: "/v1/vendor",
    indent: "/v1/indent",
    schedules: "/v1/schedules",
    customers: "/v1/customers",
    projectMaterialAssign: "/v1/project/sub/upload",
    purchase: "/v1/purchaseorder",
    approvals: "/v1/approvals",
    organization: "/v1/organization",
    userprofiles: "/v1/userprofiles",
    roles: "/v1/roles",
    grn: "/v1/grn",
    notifications: "/v1/notifications",
    emailTemplates: "/v1/emailtemplates",
    stock: "/v1/stockHistory"
};

export const getToken = () =>
    Cookies.get("authToken") ? Cookies.get("authToken") : null;

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

// Create an Axios instance
export const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: getAuthorizationHeader() },
  });

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized, handle logout
      handleLogout();
    }
    return Promise.reject(error);
  }
);

const handleLogout = () => {
    Cookies.remove("authToken");
    localStorage.removeItem("loggedUser");
    window.location.href = '/login';
};
