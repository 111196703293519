import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getNotificationList } from "../queries/Notifications";
import { Loader } from "./utils/Loader";
import { NavLink } from "react-router-dom";
import { Empty } from "antd";
import moment from "moment";

export default function NotificationList() {
    const { isLoading, isError, data } = useQuery({
        queryKey: ["notification"],
        queryFn: getNotificationList,
    });

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );
    return (
        <div>
            <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">
                Notifications
            </h3>
            <div className="bg-white p-8">
                <div className="flow-root">
                    <ul
                        role="list"
                        className="divide-y divide-gray-200 grid grid-cols-1 max-h-[250px] overflow-y-auto"
                    >
                        {data?.result?.length > 0 &&
                            data?.result?.map((notify) => (
                                <NavLink
                                    to={`/${notify.type}/${notify.request_id}`}
                                    key={notify._id}
                                >
                                    <li className="py-3 sm:py-4 cursor-pointer">
                                        <div className="flex items-center">
                                            <div className="flex-1 min-w-0">
                                                <p className="text-base font-bold text-gray-900 truncate dark:text-white">
                                                    {notify.message}
                                                </p>
                                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                    Type: {notify.type}
                                                </p>
                                            </div>
                                            <div className="inline-flex items-center text-base font-medium text-gray-900 dark:text-white">
                                                {moment(
                                                    notify.createdAt
                                                ).format("MMM-DD")}
                                            </div>
                                        </div>
                                    </li>
                                </NavLink>
                            ))}

                        {data?.result?.length === 0 && (
                            <Empty description={"No Notification List"} />
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}
