import { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

const useModulePermissions = (userData) => {
  const getPermission = (moduleName) => {
    return userData?.profile?.modulePermissions?.find((item) => item.moduleName === moduleName && item.status);
  };

  const [permissions, setPermissions] = useState({
    showCustomerMenu: getPermission('Customers'),
    showProjectMenu: getPermission('Project'),
    showMaterialMenu: getPermission('Materials'),
    showDashboardMenu: getPermission('Dashboard'),
    showVendorMenu: getPermission('Vendor'),
    showIndentMenu: getPermission('Indent'),
    showScheduleMenu: getPermission('Schedules'),
    showCategoryMenu: getPermission('Category'),
    showUsersMenu: getPermission('Users'),
    showDepartmentMenu: getPermission('Department'),
    showApprovalsMenu: getPermission('Approvals'),
    showUserProfilesMenu: getPermission('UserProfiles'),
    showRolesMenu: getPermission('Roles'),
    showPurchaseMenu: getPermission('PurchaseOrder'),
    showOrganizationMenu: getPermission('Organization'),
    showGrnMenu: getPermission('Grn'),
    showStockMenu: getPermission('Stock'),
  });


  const prevUserDataRef = useRef();
  useEffect(() => {
    if (!isEqual(prevUserDataRef.current, userData)) {
      setPermissions({
      showCustomerMenu: getPermission('Customers'),
      showProjectMenu: getPermission('Project'),
      showMaterialMenu: getPermission('Materials'),
      showDashboardMenu: getPermission('Dashboard'),
      showVendorMenu: getPermission('Vendor'),
      showIndentMenu: getPermission('Indent'),
      showScheduleMenu: getPermission('Schedules'),
      showCategoryMenu: getPermission('Category'),
      showUsersMenu: getPermission('Users'),
      showDepartmentMenu: getPermission('Department'),
      showApprovalsMenu: getPermission('Approvals'),
      showUserProfilesMenu: getPermission('UserProfiles'),
      showRolesMenu: getPermission('Roles'),
      showPurchaseMenu: getPermission('PurchaseOrder'),
      showOrganizationMenu: getPermission('Organization'),
      showGrnMenu: getPermission('Grn'),
      showStockMenu: getPermission('Stock'),
      });
    }
    prevUserDataRef.current = userData;
  }, [userData]);

  return permissions;
  
};

export default useModulePermissions;