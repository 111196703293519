import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getCategoryList } from '../../queries/Categories';
import { useQuery } from "@tanstack/react-query";
import { uploadimage } from "../../queries/Materials";
import toast from "react-hot-toast";


export default function AddMaterial(props) {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const { material, isEdit, isView, isLoading, closeModel, edMaterial, adMaterial } = props;
  const closingModel = () => {
    closeModel();
  };
  
      // const [setUploadedFiles] = useState( null);
      // const [currentFile, setCurrentFile] = useState(null);
        const [previewUrl, setPreviewUrl] = useState(material?.image?.url ||null);
  
  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm(
    isEdit || isView ? { 
      defaultValues: {
        name: material.name,
        category_id: material.category_id,
        sku: material.sku,
        hsn_code: material.hsn_code,
        brand: material.brand,
        model: material.model,
        cost: material.cost,
        unit: material.unit,
        image: material.image,
        description: material.description,
        status: material.status,
      }
    } : { 
      defaultValues: {
        name: "",
        category_id: "",
        sku: "",
        hsn_code: "",
        brand: "",
        model: "",
        cost: "",
        unit: "",
        image:null,
        description: "",
        status: "",
      } 
    }
  );

  const { data } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoryList,
  });
  useEffect(() => {
  return () => {
    if (previewUrl) URL.revokeObjectURL(previewUrl);
  };
}, [previewUrl]);
 const handleFileChange = (e) => {

    const file = e.target.files && e.target.files[0]; // Check if files exist;
    if (file) {
      const previewUrl = URL.createObjectURL(file); // Generate preview URL
      setPreviewUrl(previewUrl);
          // console.log(previewUrl);
      // setCurrentFile(file);

      uploadFiles(file);
      console.log("done")
   }
  };

    //const uploadFiles = useQueryMutation(uploadFilesGrn, queryClient, 'grn');

    // const deleteFile = (file) => {
    //     if(file?._id) {
    //         const newFiles = uploadedFiles.filter((f) => f !== file);
    //         setUploadedFiles(newFiles);
    //     }else{
    //         const newFiles = currentFile.filter((f) => f !== file);
    //         setCurrentFile(newFiles);
    //     }
    // }

  const uploadFiles = (file) => {
  //  if(e && e.preventDefault) e.preventDefault();
    //setUploadedFiles(grn?.attachments || [])
    // console.log(file)
        if (file) {
    const formData = new FormData();
    // currentFile.forEach((file) => {
      formData.append('file', file);
    // });
    const payload = {
        id: material._id,
        body: formData
    }
    uploadimage(payload).then((res) => {
        // setUploadedFiles(res?.data);
        console.log(res);
        setValue("image", res?.result);
        // setCurrentFile(null);
    })
    .catch((error) => {
    console.error("Upload failed", error);
     const previewUrl = null;
        setPreviewUrl(previewUrl);
    toast.error("Failed to upload image");
  });
  }
    // console.log(uploadedFiles);
    
  }
  const onSubmit = (data) => {
    if(isEdit) {
      const payload = {
        id: material._id,
        body: {
          ...data,
          org_id: userData.org_id,
        }
      }
      edMaterial(payload);
      closeModel();
    }else{
      const payload = {
        ...data,
        org_id: userData.org_id,
      }
      adMaterial(payload);
    }
    closeModel();
  };


  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[40%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">
              {isEdit ? "Edit Material" : isView ?  "View Material" : "Add New Material"}
          </h3>
          <p className="text-[14px] text-[#656F7D]">
              Enter Material details and add to list
              {isEdit ? " or update material details" : isView ? " or view material details" : ""}
          </p>

        </div>
        <div className="w-[60%]">
          <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
            <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Material Details
              </h4>

              <div className="grid grid-cols-2 gap-8 text-left">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Material Name <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("name", {
                        required: "Material Name is required",
                      })}
                    />
                  </div>
                  {errors.name && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.name?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Category <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.category_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("category_id", {
                        required: "Category is required",
                      })}
                    >
                      <option value="">Select Category</option>
                      {data?.result.map((c) => (
                        <option key={c._id} value={c._id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.category_id && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.category_id?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      SKU <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.sku ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("sku", {
                        required: "SKU is required",
                      })}
                    />
                  </div>
                  {errors.sku && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.sku?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      HSN Code <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.hsn_code ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("hsn_code", {
                        required: "HSN is required",
                      })}
                    />
                  </div>
                  {errors.hsn_code && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.hsn_code?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Brand Name <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.brand ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("brand", {required: "Brand is required",})}
                    />
                  </div>
                  {errors.brand && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.brand?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Model <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.model ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("model", {required: "Model is required",})}
                    />
                  </div>
                  {errors.model && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.model?.message}
                    </p>
                  )}
                </div>
                
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Cost <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.cost ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="number"
                      {...register("cost", {
                        required: "Selling Price is required",
                      })}
                    />
                  </div>
                  {errors.cost && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.cost?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Unit <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.unit ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("unit", {
                        required: "Unit is required",
                      })}
                    >
                      <option value="">Select Unit</option>
                      <option value="Nos (Numbers)">Nos (Numbers)</option>
                      <option value="Pcs (Pieces)">Pcs (Pieces)</option>
                      <option value="Sq.m (Square Meters)">Sq.m (Square Meters)</option>
                      <option value="L.M (Linear Meter)">L.M (Linear Meter)</option>
                      <option value="Cbm (Cubic Meter)">Cbm (Cubic Meter)</option>
                      <option value="Kg (Kilograms)">Kg (Kilograms)</option>
                      <option value="Rolls">Rolls</option>
                      <option value="Box">Box</option>
                      <option value="Set">Set</option>
                      <option value="Litre (L)">Litre (L)</option>
                      <option value="Kg/m² (Kilograms per Square Meter)">Kg/m² (Kilograms per Square Meter)</option>
                      <option value="M² (Meter Square)">M² (Meter Square)</option>
                      <option value="Sq.ft (Square Feet)">Sq.ft (Square Feet)</option>
                      <option value="Ctn (Carton)">Ctn (Carton)</option>
                      <option value="Bags">Bags</option>
                      <option value="Pairs">Pairs</option>
                      <option value="Box">Box</option>
                      <option value="Sets">Sets</option>
                      <option value="Kg/litre">Kg/litre</option>
                      <option value="M (Meter)">M (Meter)</option>
                    </select>
                  </div>
                  {errors.unit && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.unit?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Description
                    </label>
                    <input
                      className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      type="text"
                      {...register("description")}
                    />
                  </div>
                </div>

                {/* <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Status
                    </label>
                    <div className="flex">
                      <p className="flex mr-3">
                        <input
                          {...register("status", {
                            required: "Status is required",
                          })}
                          type="radio"
                          defaultChecked
                          value={true}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          Active
                        </span>
                      </p>
                      <p className="flex">
                        <input
                          {...register("status", {
                            required: "Status is required",
                          })}
                          type="radio"
                          value={false}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          Disable
                        </span>
                      </p>
                    </div>
                  </div>
                  {errors.status && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.status?.message}
                    </p>
                  )}
                </div> */}
    <div className="upload-container">
      <label htmlFor="fileUpload" className="upload-area">
        {previewUrl ? (
          <img
            src={previewUrl}
            alt="Preview"
            className="image-preview"
            title="Change Image"
          />
        ) : (
          <p className="upload-text">Click here to upload image</p>
        )}
      </label>
      {!isView ? (
      <input
        type="file"
        id="fileUpload"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      ) : (
        <p></p>
      )}
    </div>
              </div>
            </div>
            {!isView && (
              <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={props.isLoading}
              >
                {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
              </button>
            </p>
            )}
            
          </form>
        </div>
      </div>
    </div>
  );
}
