import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader, Loader } from "../../components/utils/Loader";
import { getProjectList } from "../../queries/Projects";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { Modal, Table, InputNumber } from "antd";
import AssignMaterial from "./AssignMaterial";
import moment from "moment";
import toast from "react-hot-toast";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { Empty } from "antd";
import { getPurchaseById, purchaseApproval } from "../../queries/PurchaseOrder";
import { getVendorList } from "../../queries/Vendors";
import { useQueryMutation } from "../../hooks/useMutation";

export default function ApprovePurchase(props) {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const params = useParams();
    const [vendorName, setVendorName] = useState("");
    const [comments, setComments] = useState('');
    const [isReject, setIsReject] = useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const { data: purchaseData, refetch, isError, isLoading } = useQuery({
        queryKey: ["purchase", params.id],
        queryFn: () => getPurchaseById(params.id),
        enabled: !!params.id,
        refetchOnWindowFocus: true,
    });
    const { data: vendorData } = useQuery({
        queryKey: ["vendor"],
        queryFn: getVendorList,
    });

    const approvedPurchase = useQueryMutation(purchaseApproval, queryClient, 'purchase');

    const approvePO = (status) => {
        if (status === 'rejected' && comments === '') {
            toast.error('Please Enter your Reason for Reject this Purchase Order');
            return;
        }
        const payload = {
            body: {
                approver: userData?._id,
                status: status,
                comment: comments    
            },
            id: params.id
        }
        approvedPurchase.mutate(payload);
        navigate('/dashboard')
    }

    

    useEffect(() => {
        refetch();    
    }, [params.id, refetch]);

    useEffect(() => {
        if (vendorData && vendorData?.result) {
            const vendor = vendorData?.result.find(
                (vend) => vend._id === purchaseData?.result?.vendor_id
            );
            setVendorName(vendor?.name);
        }    
    }, [vendorData, purchaseData]);

    if (isLoading) {
        return <Loader />;
    }
    if (!purchaseData || !purchaseData.result) {
        return <div className="py-44"><Empty /></div>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
        },
        {
            title: "Unit",
            dataIndex: "unit",
            key: "unit",
        },
        {
            title: "Rate",
            dataIndex: "cost",
            key: "cost",
        },
        {
            title: "QTY",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Amount",
            dataIndex: "price",
            key: "price",
        },
        ...(purchaseData?.result?.tax_type === 'GST' ? [
            {
                title: "SGST",
                dataIndex: "total_tax",
                key: "total_tax",
                render: (text) => text / 2,
            },
            {
                title: "CGST",
                dataIndex: "total_tax",
                key: "total_tax",
                render: (text) => text / 2,
            },

        ] : [
            {
                title: "IGST",
                dataIndex: "total_tax",
                key: "total_tax",
            },
        ]),
        {
            title: "GST or IGST %",
            dataIndex: "tax_percentage",
            key: "tax_percentage",
        },
        {
            title: "Total",
            dataIndex: "total",
            key: "total",
        },
    ];

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
            <p className="mb-8 flex justify-end">
                <NavLink to="/notification">
                    <img src={closeIcon} alt="" />
                </NavLink>
            </p>
            <div className="flex">
                <div className="w-[20%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        Purchase Order Approval
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        To Approve or Reject the PO
                    </p>
                </div>
                <div className="w-[80%]">
                    <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                        <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                            PO Details
                        </h4>
                        <div className="grid grid-cols-4 gap-8 text-left mb-8">
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Indent Number
                                </label>
                                <p>{purchaseData?.result?.indent_number}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Project Code
                                </label>
                                <p>{purchaseData?.result?.project_code}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Vendor
                                </label>
                                <p>{purchaseData?.result?.project_code}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Freight Terms
                                </label>
                                <p>{purchaseData?.result?.freight_terms}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Payment Terms
                                </label>
                                <p>{purchaseData?.result?.payment_terms}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Payment Mode
                                </label>
                                <p>{purchaseData?.result?.payment_mode}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Ref. Quote
                                </label>
                                <p>{purchaseData?.result?.quot_ref}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Quote Date
                                </label>
                                <p>
                                    {moment(
                                        purchaseData?.result?.quot_date
                                    ).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Required Date
                                </label>
                                <p>{moment(
                                        purchaseData?.result?.required_date
                                    ).format("DD-MM-YYYY")}
                                    </p>
                            </div>

                            
                        </div>
                        <div className="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between">
                            <h4 className="text-[20px] text-left">
                                Assign Materials
                            </h4>
                        </div>
                        <div>
                            {purchaseData?.result?.materials?.length > 0 && (
                                <Table
                                    pagination={{ pageSize: 6 }}
                                    rowKey={(record) => record.material_id}
                                    className="w-full text-left"
                                    dataSource={purchaseData?.result?.materials}
                                    columns={columns}
                                />
                            )}
                        </div>
                    </div>
                    <p className="text-right mt-8 flex items-center justify-end">
                        <button onClick={() => setIsReject(true)} className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3">
                            Reject
                        </button>
                        <button
                            type="Submit"
                            className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                            onClick={() => approvePO('approved')}
                        >
                            Approve
                        </button>
                    </p>
                </div>
            </div>
            {isReject && (
                <div
                id="billing-modal"
                tabindex="-1"
                aria-hidden="true"
                className="flex overflow-y-auto overflow-x-hidden bg-opacity-60 bg-black fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full"
            >
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Reason for Reject
                            </h3>
                            <button
                                type="button"
                                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="authentication-modal"
                                onClick={() => setIsReject(false)}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5">
                            <div>
                                <textarea
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    rows={5}
                                    placeholder="Comments"
                                    name=""
                                    id=""
                                    onInput={(e) => setComments(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div class="flex items-center justify-end md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[30px] w-[70px] border border-[#007AC8] rounded text-white text-[12px] font-bold uppercase"
                                onClick={() => approvePO('rejected')}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}
