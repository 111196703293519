import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getIndentList,
    addIndent,
    editIndent,
    deleteIndent,
} from "../../queries/Indent";
import { getProjectList } from "../../queries/Projects";
import AddIndent from "./AddIndent";
import { Loader } from "../../components/utils/Loader";
import IndentListTable from "./IndentListTable";
import { useQueryClient } from "@tanstack/react-query";
import { Table } from "antd";
import moment from "moment";
import SearchList from "../../components/SearchList";
import { useFilteredList } from "../../hooks/useFilteredList";
import {useQueryMutation} from '../../hooks/useMutation';
import { IndentColumns } from "../../utils/colConfig";
import useModulePermissions from '../../hooks/useModulePermissions';
import { download } from "../../queries/Email";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.indent_number.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function IndentList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const { isLoading, isError, data } = useQuery({
        queryKey: ["indent"],
        queryFn: getIndentList,
    });
    const { data: projData } = useQuery({
        queryKey: ["project"],
        queryFn: getProjectList,
    });
    const {
        filteredList: indentList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);


    const deleteIndentList = useQueryMutation(deleteIndent, queryClient, 'indent');
    const addIndentList = useQueryMutation(addIndent, queryClient, 'indent');
    const editIndentList = useQueryMutation(editIndent, queryClient, 'indent');  
    const downloadIndentList = useQueryMutation(download, queryClient, 'download');

    const closeModel = () => {
        setShowModel(false);
    };

    const downIndent = (obj) => {
        downloadIndentList.mutate(obj, {
            onSuccess: (res) => {
                       const blob = new Blob([res], {type: 'application/pdf'})
                // const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', 'example.pdf'); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
            }
        });
    }

    const deleIndent = (id) => {
        deleteIndentList.mutate(id);
    };

    const edIndent = (obj) => {
        editIndentList.mutate(obj);
    };

    const adIndent = (obj) => {
        addIndentList.mutate(obj);
    };

    const columns = IndentColumns(deleIndent, edIndent, downIndent, isLoading, projData);

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Indent No. or Project Code..."}
                        />
                        {permissions.showIndentMenu !== undefined && permissions.showIndentMenu.write && (
                            <button
                                onClick={() => setShowModel(true)}
                                className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                            >
                                <img src={whitePlus} alt="" />
                                <span className="ml-3">Add New Indent</span>
                            </button>
                        )}
                        
                    </div>

                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={indentList}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Indent here
                    </p>
                    {permissions.showIndentMenu !== undefined && permissions.showIndentMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Indent</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showIndentMenu !== undefined && permissions.showIndentMenu.write && (
                <AddIndent
                    closeModel={closeModel}
                    adIndent={adIndent}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
}
