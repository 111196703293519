import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import toast from "react-hot-toast";
import { uploadFilesOrganization } from "../../queries/Organization";
import attachIcon from "../../assets/image/attachment-white.svg";
import deleteIcon from "../../assets/image/delete-white.svg";
import { Link } from "react-router-dom";

export default function AddUser(props) {
    const userName = JSON.parse(localStorage.getItem("loggedUser"));
    // const [uploadedFiles, setUploadedFiles] = useState([]);
    // const [currentFile, setCurrentFile] = useState([]);
    const { organization } = props;
    const closingModel = () => {
        props.closeModel();
    };
        const [previewUrl, setPreviewUrl] = useState(organization?.logo?.url ||null);

    const {
        register,
        reset,
        formState: { errors },
        setValue,
        handleSubmit,
        clearErrors,
    } = useForm({
        defaultValues: {
            name: organization?.name,
            address: organization?.address,
            city: organization?.city,
            state: organization?.state,
            country: organization?.country,
            zip: organization?.zip,
            phone: organization?.phone,
            email: organization?.email,
            poTermsAndConditions: organization?.poTermsAndConditions,
            logo: organization?.logo,
            org_id: userName.org_id,
        },
    });
 useEffect(() => {
  return () => {
    if (previewUrl) URL.revokeObjectURL(previewUrl);
  };
}, [previewUrl]);
    const handleFileChange = (e) => {
        const file = e.target.files && e.target.files[0]; // Check if files exist;
    if (file) {
      const previewUrl = URL.createObjectURL(file); // Generate preview URL
      setPreviewUrl(previewUrl);
          // console.log(previewUrl);
      // setCurrentFile(file);

      uploadFiles(file);
      console.log("done")
   }
    };
    const uploadFiles = (file) => {
        // e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        const payload = {
            body: formData,
        };
        uploadFilesOrganization(payload).then((res) => {
            // setUploadedFiles([res?.data]);
            setValue("logo", res?.result);
            // setCurrentFile([]);
        }).catch(err =>{
            console.log(err);
        const previewUrl = null;
        setPreviewUrl(previewUrl);

        });
    };

    // const deleteFile = (file) => {
    //     if (file?.size) {
    //         const newFiles = currentFile.filter((f) => f !== file);
    //         setCurrentFile(newFiles);
    //     } else {
    //         const newFiles = uploadedFiles.filter((f) => f !== file);
    //         setUploadedFiles(newFiles);
    //         setValue('logo', newFiles)
    //     }
    // };

    const onSubmit = (data) => {
        const tempObj = {
            id: organization._id,
            body: data,
        };
        props.editingOrganization(tempObj);
        props.closeModel();
        //reset();
    };

    // useEffect(() => {
    //     if(organization?.logo?.length > 0){            
    //         clearErrors("logo")
    //     }
    // }, [])

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">Edit User</h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Edit user details and update to list
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-5 rounded-md max-h-full overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                User Details
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Organization Name
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Organization Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Address
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("address", {
                                                required: "Address is required",
                                            })}
                                        />
                                    </div>
                                    {errors.address && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.address?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            City
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("city", {
                                                required: "City is required",
                                            })}
                                        />
                                    </div>
                                    {errors.city && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.city?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            State
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("state", {
                                                required: "State is required",
                                            })}
                                        />
                                    </div>
                                    {errors.state && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.state?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Zip
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("zip", {
                                                required: "Zip is required",
                                            })}
                                        />
                                    </div>
                                    {errors.zip && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.zip?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Country
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("country", {
                                                required: "State is required",
                                            })}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Phone
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("phone", {
                                                required: "Phone is required",
                                            })}
                                        />
                                    </div>
                                    {errors.phone && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Email
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("email", {
                                                required:
                                                    "Email Address is required",
                                                validate: {
                                                    matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                            v
                                                        ) ||
                                                        "Email address must be a valid address",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
 <div className="upload-container">
      <label htmlFor="fileUpload" className="upload-area">
        {previewUrl ? (
          <img
            src={previewUrl}
            alt="Preview"
            className="image-preview"
            title="Change Image"
          />
        ) : (
          <p className="upload-text">Click here to upload image</p>
        )}
      </label>
      {/* {!isView ? ( */}
      <input
        type="file"
        id="fileUpload"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {/* ) : (
        <p></p>
      )} */}
    </div>
                                {/* <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor="gstCertificate"
                                        >
                                            Upload Logo of your Organization
                                            (PNG or JPG)
                                            <span className="text-[red]">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            accept=".jpg, .jpeg, .png"
                                            multiple
                                            onChange={handleFileUpload}
                                        />
                                    </div>

                                    <div className="mt-5">
                                        {currentFile?.length > 0 && (
                                            <>
                                                <p className="font-bold">
                                                    Current File's
                                                </p>
                                                <ul>
                                                    {currentFile?.map(
                                                        (file, index) => (
                                                            <div
                                                                key={index}
                                                                className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between"
                                                            >
                                                                <p className="flex items-center text-[13px] text-gray-300">
                                                                    <span className="pr-2">
                                                                        <img
                                                                            src={
                                                                                attachIcon
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                    {file?.file_name ||
                                                                        file?.name}
                                                                </p>
                                                                <span onClick={() =>
                                                                        deleteFile(
                                                                            file
                                                                        )
                                                                    } className="cursor-pointer">
                                                                    <img
                                                                        src={
                                                                            deleteIcon
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </ul>
                                            </>
                                        )}
                                        {currentFile.length > 0 && (
                                            <p className="text-right pt-3">
                                                <button
                                                    className="font-bold text-[#007AC8]"
                                                    onClick={(e) =>
                                                        uploadFiles(e)
                                                    }
                                                >
                                                    Upload
                                                </button>
                                            </p>
                                        )}
                                    </div>

                                    {uploadedFiles.length > 0 && (
                                        <>
                                            <p className="font-bold">
                                                Uploaded Logo of your Organization
                                            </p>
                                            <ul>
                                                {uploadedFiles?.map(
                                                    (file, index) => (
                                                        <div
                                                            key={index}
                                                            className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between"
                                                        >
                                                            <Link
                                                                to={file?.url}
                                                                target="_blank"
                                                                className="flex items-center text-[13px] text-gray-300"
                                                            >
                                                                <span className="pr-2">
                                                                    <img
                                                                        src={
                                                                            attachIcon
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                {file?.file_name ||
                                                                    file?.name}
                                                            </Link>
                                                            
                                                                <span
                                                                    onClick={() =>
                                                                        deleteFile(
                                                                            file
                                                                        )
                                                                    }
                                                                    className="cursor-pointer"
                                                                >
                                                                    <img
                                                                        src={
                                                                            deleteIcon
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            
                                                        </div>
                                                    )
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {uploadedFiles.length <= 0 && (
                                        <p className="text-xs text-[red] mt-1" role="alert">
                                            Organization Logo is Required
                                        </p>
                                    )}
                                </div> */}

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Terms and Conditions
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register(
                                                "poTermsAndConditions",
                                                {
                                                    required:
                                                        "Terms and Conditions is required",
                                                }
                                            )}
                                        />
                                    </div>
                                    {errors.poTermsAndConditions && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {
                                                errors.poTermsAndConditions
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <p className="text-right mt-8 flex items-center justify-end">
                            <button
                                onClick={closingModel}
                                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                            >
                                Cancel
                            </button>
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                disabled={props.isLoading}
                            >
                                {props.isLoading ? <BtnLoader /> : "Edit"}
                            </button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}
