import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
    EMAIL_ONLY,
    PHONE_NO_ONLY,
    WEBSITE_ONLY,
} from "../../components/utils/Regex";
import { useQuery } from "@tanstack/react-query";
import { getCategoryList } from "../../queries/Categories";
import toast from "react-hot-toast";
import { uploadFilesVendor } from "../../queries/Vendors";
import attachIcon from "../../assets/image/attachment-white.svg";
import deleteIcon from "../../assets/image/delete-white.svg";
import { Link } from "react-router-dom";

export default function ContractorForm({ isEdit, isView, vendor }) {
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();
    const { data: catData } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategoryList,
    });
    const [uploadedFiles, setUploadedFiles] = useState(
        isEdit || isView ? vendor.attachments : []
    );
    const [currentFile, setCurrentFile] = useState([]);

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + uploadedFiles.length > 2) {
            toast.error("You can upload a maximum of 2 files.");
            setCurrentFile([]);
            return;
        }
        setCurrentFile([...currentFile, ...files]);
    };
    const uploadFiles = (e) => {
        e.preventDefault();
        const formData = new FormData();
        currentFile.forEach((file) => {
            formData.append("files", file);
        });
        const payload = {
            body: formData,
        };
        uploadFilesVendor(payload).then((res) => {
            setUploadedFiles([...uploadedFiles, ...res?.data]);
            setValue("attachments", [...uploadedFiles, ...res?.data]);
            setCurrentFile([]);
        });
    };
    const deleteFile = (file) => {
        if (file?.size) {
            const newFiles = currentFile.filter((f) => f !== file);
            setCurrentFile(newFiles);
        } else {
            const newFiles = uploadedFiles.filter((f) => f !== file);
            setUploadedFiles(newFiles);
            setValue("attachments", newFiles);
        }
    };

    return (
        <div>
            <div className="grid grid-cols-2 gap-8 text-left mt-5 mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="address"
                        >
                            Office Address <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="address"
                            type="text"
                            className={
                                errors?.address
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("address", {
                                required: "Supplier Address is required",
                            })}
                        />
                    </div>
                    {errors?.address && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.address?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="type_of_contractor"
                        >
                            Type of Contractor{" "}
                            <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="type_of_contractor"
                            type="text"
                            className={
                                errors?.type_of_contractor
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("type_of_contractor", {
                                required: "Type of Contractor is required",
                            })}
                        />
                    </div>
                    {errors?.type_of_contractor && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.type_of_contractor?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="experience_in_field"
                        >
                            Exp. in Field <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="experience_in_field"
                            type="text"
                            className={
                                errors?.experience_in_field
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("experience_in_field", {
                                required: "Exp. in Field is required",
                            })}
                        />
                    </div>
                    {errors?.experience_in_field && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.experience_in_field?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="project_completed_count"
                        >
                            Project Completed Count
                        </label>
                        <input
                            id="project_completed_count"
                            type="text"
                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            {...register("project_completed_count")}
                        />
                    </div>
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Bank Details
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.bank_name"
                        >
                            Bank Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.bank_name"
                            type="text"
                            className={
                                errors?.account_details?.bank_name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.bank_name", {
                                required: "Bank Name is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.bank_name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.bank_name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.branch_name"
                        >
                            Branch Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.branch_name"
                            type="text"
                            className={
                                errors?.account_details?.branch_name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.branch_name", {
                                required: "Branch Name is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.branch_name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.branch_name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.bank_account_no"
                        >
                            Account Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.bank_account_no"
                            type="text"
                            className={
                                errors?.account_details?.bank_account_no
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.bank_account_no", {
                                required: "Account Number is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.bank_account_no && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.bank_account_no?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.type_of_account"
                        >
                            Account Type <span className="text-[red]">*</span>
                        </label>
                        <select
                            className={
                                errors?.account_details?.type_of_account
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.type_of_account", {
                                required: "Account Type is required",
                            })}
                        >
                            <option value="Savings">Savings</option>
                            <option value="Current">Current</option>
                        </select>
                    </div>
                    {errors.account_details?.type_of_account && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.type_of_account?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.ifsc_code"
                        >
                            IFSC Code <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.ifsc_code"
                            type="text"
                            className={
                                errors?.account_details?.ifsc_code
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.ifsc_code", {
                                required: "IFSC Code is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.ifsc_code && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.ifsc_code?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.pan_no"
                        >
                            PAN Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.pan_no"
                            type="text"
                            className={
                                errors?.account_details?.pan_no
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.pan_no", {
                                required: "PAN Number is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.pan_no && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.pan_no?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="aadhaar"
                        >
                            Aadhar Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="aadhaar"
                            type="text"
                            className={
                                errors?.aadhaar
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("aadhaar", {
                                required: "Aadhar Number is required",
                            })}
                        />
                    </div>
                    {errors?.aadhaar && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.aadhaar?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="gst"
                        >
                            GST <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="gst"
                            type="text"
                            className={
                                errors?.gst
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("gst", {
                                required: "GST Number is required",
                            })}
                        />
                    </div>
                    {errors?.gst && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.gst?.message}
                        </p>
                    )}
                </div>

                <div>
                    <div>
                        {!isView && (
                            <>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor="gstCertificate"
                                >
                                    Upload GST Certificate and Cancel Cheque
                                    Leaf (PNG, JPG, PDF and max 2 Files)
                                    <span className="text-[red]">*</span>
                                </label>
                                <input
                                    type="file"
                                    id="fileUpload"
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    multiple
                                    onChange={handleFileUpload}
                                />
                                <div className="mt-5">
                                    {currentFile?.length > 0 && (
                                        <>
                                            <p className="font-bold">
                                                Current File's
                                            </p>
                                            <ul>
                                                {currentFile?.map(
                                                    (file, index) => (
                                                        <div
                                                            key={index}
                                                            className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between"
                                                        >
                                                            <p className="flex items-center text-[13px] text-gray-300">
                                                                <span className="pr-2">
                                                                    <img
                                                                        src={
                                                                            attachIcon
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                {file?.file_name ||
                                                                    file?.name}
                                                            </p>
                                                            <span
                                                                onClick={() =>
                                                                    deleteFile(
                                                                        file
                                                                    )
                                                                }
                                                                className="cursor-pointer"
                                                            >
                                                                <img
                                                                    src={
                                                                        deleteIcon
                                                                    }
                                                                    alt=""
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                            </ul>
                                        </>
                                    )}
                                    {currentFile.length > 0 && (
                                        <p className="text-right pt-3">
                                            <button
                                                className="font-bold text-[#007AC8]"
                                                onClick={(e) => uploadFiles(e)}
                                            >
                                                Upload
                                            </button>
                                        </p>
                                    )}
                                </div>
                            </>
                        )}

                        {uploadedFiles.length > 0 && (
                            <>
                                <p className="font-bold">
                                    Uploaded GST and Cancel Cheque Leaf File's
                                </p>
                                <ul>
                                    {uploadedFiles?.map((file, index) => (
                                        <div
                                            key={index}
                                            className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between"
                                        >
                                            <Link
                                                to={file?.url}
                                                target="_blank"
                                                className="flex items-center text-[13px] text-gray-300"
                                            >
                                                <span className="pr-2">
                                                    <img
                                                        src={attachIcon}
                                                        alt=""
                                                    />
                                                </span>
                                                {file?.file_name || file?.name}
                                            </Link>
                                            {!isView && (
                                                <span
                                                    onClick={() =>
                                                        deleteFile(file)
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <img
                                                        src={deleteIcon}
                                                        alt=""
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                    {uploadedFiles.length < 2 && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            GST Certificate and Cancel Cheque Leaf both is
                            required
                        </p>
                    )}
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Contractor Details
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.name"
                        >
                            Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.name"
                            type="text"
                            className={
                                errors?.contact_person_a?.name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.name", {
                                required: "Contact Person - A Name is required",
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.phone_num"
                        >
                            Phone Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.phone_num"
                            type="text"
                            className={
                                errors?.contact_person_a?.phone_num
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.phone_num", {
                                required:
                                    "Contact Person - A Phone is required",
                                pattern: {
                                    value: PHONE_NO_ONLY,
                                    message: "Invalid Mobile Number",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.phone_num && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.phone_num?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.email"
                        >
                            Email <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.email"
                            type="text"
                            className={
                                errors?.contact_person_a?.email
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.email", {
                                required:
                                    "Contact Person - A Email is required",
                                pattern: {
                                    value: EMAIL_ONLY,
                                    message: "Invalid Email Address",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.email && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.email?.message}
                        </p>
                    )}
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Alternate Person Details
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.name"
                        >
                            Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.name"
                            type="text"
                            className={
                                errors?.contact_person_b?.name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.name", {
                                required: "Contact Person - B Name is required",
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.phone_num"
                        >
                            Phone Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.phone_num"
                            type="text"
                            className={
                                errors?.contact_person_b?.phone_num
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.phone_num", {
                                required:
                                    "Contact Person - B Phone is required",
                                pattern: {
                                    value: PHONE_NO_ONLY,
                                    message: "Invalid Mobile Number",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.phone_num && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.phone_num?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.email"
                        >
                            Email <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.email"
                            type="text"
                            className={
                                errors?.contact_person_b?.email
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.email", {
                                required:
                                    "Contact Person - B Email is required",
                                pattern: {
                                    value: EMAIL_ONLY,
                                    message: "Invalid Email Address",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.email && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.email?.message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
