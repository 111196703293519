import React from 'react';
import StockList from './StockList';

export default function Stocks() {
  return (
    <div className='bg-white p-8'>
        <StockList />
    </div>
  )
}
