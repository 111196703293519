import React, {useState} from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { BtnLoader } from "../../components/utils/Loader";
import { getDepartmentList } from "../../queries/Departments";
import { getUserProfileList } from "../../queries/UserProfiles";
import { getRoleList } from "../../queries/Roles";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import {
    EMAIL_ONLY,
    PHONE_NO_ONLY,
    NUMBERS_ONLY,
} from "../../components/utils/Regex";

export default function AddUser({closeModel, adUser, isLoading, isEdit, isView, user, edUser}) {
    const userName = JSON.parse(localStorage.getItem('loggedUser'));
    const [department, setDepartment] = useState(isEdit || isView ? user.department : "");
    const [profile, setProfile] = useState(isEdit || isView ? user.profile_id : "");
    const [role, setRole] = useState(isEdit || isView ? user.role_id : "");
    const { data } = useQuery({
        queryKey: ["departments"],
        queryFn: getDepartmentList,
    });
    const { data: profileData } = useQuery({
        queryKey: ["profiles"],
        queryFn: getUserProfileList,
    });
    const { data: roleData } = useQuery({
        queryKey: ["roles"],
        queryFn: getRoleList,
    });

    
    const closingModel = () => {
        closeModel();
    };
    


    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm(
        isEdit || isView ? { 
            defaultValues: {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                mobile: user.mobile,
                emp_code: user.emp_code,
                department: user.department,
                gender: user.gender,
                password: user.password,
                profile_id: user.profile_id,
                role_id: user.role_id,
                status: user.status
            }
        } : {
            defaultValues: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                department: "",
                emp_code:"",
                password: "",
                gender: "",
                profile_id: "",
                role_id: "",
                status: ""
            }
        }
       
        
    );

    const onSubmit = (data) => {
        if(isEdit){
            const payload = {
                id: user._id,
                body: {
                    org_id: userName.org_id,
                    ...data,
                }
            }
            edUser(payload);
            closeModel();
        }else{
            const payload = {
                org_id: userName.org_id,
                ...data,
            }
            adUser(payload);
            closeModel();
        }
    };

    

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit ? "Edit User" : isView ?  "View User" : "Add New User"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Enter User details and add to list
                        {isEdit ? " or update user details" : isView ? " or view user details" : ""}
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                User Details
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label className="text-[#2B384A] text-[12px] block mb-2" htmlFor="">First Name <span className="text-[red]">*</span></label>
                                        <input
                                            className={errors.first_name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("first_name", {
                                                required:
                                                    "First Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.first_name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.first_name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Last Name <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.last_name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("last_name", {
                                                required:
                                                    "Last Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.last_name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.last_name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Email <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("email", {
                                                required:
                                                    "Email Address is required",
                                                pattern: {
                                                    value: EMAIL_ONLY,
                                                    message:
                                                        "Invalid Email Address",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>

                                {!isView && !isEdit ? (
                                    <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Password <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.password ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="password"
                                            {...register("password", {
                                                required:
                                                    "Password is required",
                                            })}
                                        />
                                    </div>
                                    {errors.password && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.password?.message}
                                        </p>
                                    )}
                                </div>
                                ) : null}
    

                                
                                

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Mobile <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.mobile ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("mobile", {
                                                required:
                                                    "Mobile No is required",
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Invalid Mobile Number",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.mobile && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.mobile?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Employee Code <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.emp_code ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            maxLength={6}
                                            {...register("emp_code", {
                                                required:
                                                    "Employee Code is required",
                                                pattern: {
                                                    value: NUMBERS_ONLY,
                                                    message:
                                                        "Invalid Employee Code",
                                                },
                                                maxLength: {
                                                    value: 8,
                                                    message: "Employee Code maximum 8 characters",
                                                }
                                            })}
                                        />
                                    </div>
                                    {errors.emp_code && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.emp_code?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Department <span className="text-[red]">*</span>
                                        </label>
                                        <select
                                            className={errors.department ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            {...register("department", {
                                                required: "Select Department",
                                            })}
                                            value={department}
                                            onChange={(e) => setDepartment(e.target.value)}
                                        >
                                            <option value="">
                                                Select Department
                                            </option>
                                            {data?.result.map(d => (
                                                <option key={d._id} value={d._id}>{d.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors?.department && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.department?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            User Profile <span className="text-[red]">*</span>
                                        </label>
                                        <select
                                            className={errors.profile_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            {...register("profile_id", {
                                                required: "Select Profile",
                                            })}
                                            value={profile}
                                            onChange={(e) => setProfile(e.target.value)}
                                        >
                                            <option value="">
                                                Select Profile
                                            </option>
                                            {profileData?.result.map((p) => (
                                                <option
                                                    key={p._id}
                                                    value={p._id}
                                                >
                                                    {p.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors?.profile_id && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.profile_id?.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Role <span className="text-[red]">*</span>
                                        </label>
                                        <select
                                            className={errors.role_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            {...register("role_id", {
                                                required: "Select Role",
                                            })}
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                        >
                                            <option value="">
                                                Select Role
                                            </option>
                                            {roleData?.result.map((p) => (
                                                <option
                                                    key={p._id}
                                                    value={p._id}
                                                >
                                                    {p.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors?.role_id && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.role_id?.message}
                                        </p>
                                    )}
                                </div>
                                
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Gender <span className="text-[red]">*</span>
                                        </label>
                                        <select
                                            className={errors.gender ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            {...register("gender", {
                                                required: "Select User Gender",
                                            })}
                                        >
                                            <option value="">
                                                Select Gender
                                            </option>
                                            <option value="male">Male</option>
                                            <option value="female">
                                                Female
                                            </option>
                                            <option value="transgender">
                                                Transgender
                                            </option>
                                        </select>
                                    </div>
                                    {errors.gender && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.gender?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Status
                                        </label>
                                        <div className="flex">
                                            <p className="flex mr-3">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    defaultChecked={isEdit || isView ? user.status : true}
                                                    value={true}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Active
                                                </span>
                                            </p>
                                            <p className="flex">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    defaultChecked={isEdit || isView ? !user.status : false}
                                                    value={false}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Disable
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    {errors.status && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.status?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!isView && (
                            <p className="text-right mt-8 flex items-center justify-end">
                                <button
                                    onClick={closingModel}
                                    className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="Submit"
                                    className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                            <BtnLoader />
                                        ) : isEdit ? (
                                            "Update"
                                        ) : (
                                            "Create"
                                        )}
                                </button>
                            </p>
                        )}
                        
                    </form>
                </div>
            </div>
        </div>
    );
}
