import {endPoints, baseURL} from '../components/api';
import axios from 'axios';

export const loginUser = async (loginData) => {
  const { data: response } = await axios.post(
    `${baseURL}${endPoints.login}`,
    loginData
  );
  return response;
};

export const forgotPassword = async (email) => {
  const { data: response } = await axios.post(
    `${baseURL}${endPoints.forgotpass}`,
    email
  );
  return response;
};