import React, {useState, useEffect} from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getProjectList } from '../../queries/Projects';
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { Modal, Table, InputNumber } from 'antd';
import AssignMaterial from "./AssignMaterial";
import moment from "moment";
import toast from "react-hot-toast";
import { DeleteOutlined } from "@ant-design/icons";

export default function AddIndent(props) {
  const { data: projectData } = useQuery({
    queryKey: ["project"],
    queryFn: getProjectList,
  });
  

  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const {isLoading, isEdit, isView, closeModel, indent, editingIndnt, adIndent} = props;
  const [reqDate, setReqDate] = useState(isEdit || isView ? new Date(indent.required_date) : new Date());
  const [showFilteredProj, setShowFilteredProj] = useState(isEdit || isView ? projectData?.result.filter(p => p._id === indent.project_id)[0].materials : []);
  var [selectingMat, setSelectingMat] = useState(isEdit || isView ? indent.materials : []);
  const [projCode, setProjCode] = useState(isEdit || isView ? projectData?.result.filter(p => p._id === indent.project_id)[0].project_code : '');
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const closingModel = () => {
    closeModel();
  };
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm(
    isEdit || isView ? { 
      defaultValues: {
        project_id: indent.project_id,
        description: indent.description,
        status: indent.status
      }
    } : { defaultValues: {
      project_id: '',
      description: '',
      status: true
    } }
  );

  


  const columns = [
        {
      title: 'Remove',
      render: (text, row) => (
         <DeleteOutlined
                style={{ color: "red", cursor: "pointer" }}
                onClick={(e) => handleDelete(row,e)}
                title="Delete"
              />
        ),
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%'
    },
    {
      title: 'BOQ No.',
      dataIndex: 'boq_no',
      key: 'boq_no',
      width: '10%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '15%'
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '10%'
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '10%'
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: '10%'
    },
    {
      title: 'QTY',
      dataIndex: isEdit || isView ? 'quantity' : 'material_id',
      key: 'qty',
      render: (text, row) => (
        
        <InputNumber
              defaultValue={isEdit || isView ? text : '1'}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              onChange={(e) => {changeQty(row, e)}}
            />
        ),
      width: '15%'
    },
    {
      title: 'Remarks',
      dataIndex: isEdit || isView ? 'remarks' : 'material_id',
      key: 'remarks',
      render: (text, row) => (
        <input type="text" defaultValue={isEdit || isView ? text : ''} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => changeRemark(text, row, e)} />
        ),
      width: '15%'
    }
  ]
  

  const changeQty = (row, value) => {
    if(isEdit){
      return row['quantity'] = Number(value);
    }else{
      return selectingMat.map(s => {
        if(row.material_id === s.material_id){
          s['quantity'] = Number(value);
        }
      });
    }
  }
  const changeRemark = (rcd, row, e) => {
    if(isEdit){
      return row['remarks'] = e.target.value; 
    }else{
    return selectingMat.map(s => {
      if(rcd === s.material_id){
        s['remarks'] = e.target.value;
      }
    });
  }
  }

  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
// Function to handle row deletion
  const handleDelete = (row,e) => {
    console.log("id",row);
  const newMat =  selectingMat.filter((mat) => mat.material_id !== row.material_id);
     console.log("selectingMat",selectingMat);
     setSelectingMat(newMat);
    //  return selectingMat;
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    if(isEdit){
      let tempObj = {
        id: indent._id,
        body: {
        project_code: projCode,
        indent_number: indent.indent_number,
        org_id: userData.org_id,
        date: moment(new Date()).format("MM-DD-YYYY"),
        required_date: moment(reqDate).format("MM-DD-YYYY"),
        description: data.description,
        materials: selectingMat,
      }
      }
      if(selectingMat.length > 0){
        editingIndnt(tempObj);
        closeModel();
      }else{
        toast.error('Please Assign Materials to selected Project!!!')
      }
    }else{
      let tempObj = {
        project_code: projCode,
        org_id: userData.org_id,
        date: moment(new Date()).format("MM-DD-YYYY"),
        required_date: moment(reqDate).format("MM-DD-YYYY"),
        description: data.description,
        materials: selectingMat,
      }
      if(selectingMat.length > 0){
        adIndent(tempObj);
        closeModel();
      }else{
        toast.error('Please Assign Materials to selected Project!!!')
      }
    }
    
    
  };

  const addIndentMaterial = (selectRows) => {
    setSelectingMat(selectRows);
    setIsModalOpen(false);
  }

  const changeProj = (e) => {
    let matList = [];
    setSelectingMat([]);
    const projectCde = e.target.value !== '' ? projectData.result.filter(p => p._id === e.target.value)[0].project_code : '';
    const filteredProjMat = e.target.value !== '' ? projectData.result.filter(p => p._id === e.target.value)[0].materials : [];
    console.log("filteredProjMat",filteredProjMat);
    if(projectCde !== ''){
      setProjCode(projectCde);
    }
    if(filteredProjMat.length > 0){
      filteredProjMat.map((u) => {
        const temObj = {};
        temObj.material_id = u._id;
        temObj.name = `${u.name}`;
        temObj.boq_no = `${u.boq_no}`;
        temObj.description = `${u.description}`;
        temObj.unit = `${u.unit}`;
        temObj.model = `${u.model}`;
        temObj.brand = `${u.brand}`;
        temObj.stock = `${u.stock}`;
        matList.push(temObj);
    })
    }
    setShowFilteredProj(matList);
    if(projectCde !== '' && filteredProjMat.length <= 0){
      toast.error('No Material Found for Selected Project, Please Add Material to Project!!!')
    }
  }

  useEffect(() => {
    if (selectingMat.length > 0 && !isEdit) {
      selectingMat.map((s) => {
        changeQty(s, 1)
      });
      
      console.log('isnot edit')
    }
  }, [selectingMat]);



  return (
      <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
          <p className="mb-8 text-right">
              <button onClick={closingModel}>
                  <img src={closeIcon} alt="" />
              </button>
          </p>

          <div className="flex">
              <div className="w-[20%] text-left">
                  <h3 className="text-[24px] text-[#2D3846]">Add New Indent</h3>
                  <p className="text-[14px] text-[#656F7D]">
                      Enter Indent details and add to list
                  </p>
              </div>
              <div className="w-[80%]">
                  <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
                      <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                          <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                              Indent Details
                          </h4>

                          <div className="grid grid-cols-3 gap-8 text-left mb-8">
                              <div>
                                  <div>
                                      <label
                                          className="text-[#2B384A] text-[12px] block mb-2"
                                          htmlFor=""
                                      >
                                          Project
                                      </label>
                                      <select
                                          className="border border-gray-300 text-[#2D3846] text-[16px] rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 font-bold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          {...register("project_id", {
                                              required: "Project is required",
                                              onChange: (e) => {
                                                  changeProj(e);
                                              },
                                          })}
                                      >
                                          <option value="">
                                              Select Project
                                          </option>
                                          {projectData?.result.map((c) => (
                                              <option key={c._id} value={c._id}>
                                                  {c.name}
                                              </option>
                                          ))}
                                      </select>
                                  </div>
                                  {errors?.project_id && (
                                      <p
                                          className="text-xs text-[red] mt-1"
                                          role="alert"
                                      >
                                          {errors?.project_id?.message}
                                      </p>
                                  )}
                              </div>

                              <div>
                                  <div>
                                      <label
                                          className="text-[#2B384A] text-[12px] block mb-2"
                                          htmlFor=""
                                      >
                                          Required Date
                                      </label>
                                      <DatePicker
                                          className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                          selected={reqDate}
                                          dateFormat="dd/MM/yyyy"
                                          onChange={(date) => {
                                              setReqDate(date);
                                          }}
                                      />
                                  </div>
                                  {!reqDate && (
                                      <p
                                          className="text-xs text-[red] mt-1"
                                          role="alert"
                                      >
                                          Please Select valid Required Date
                                      </p>
                                  )}
                              </div>

                              <div>
                                  <div>
                                      <label
                                          className="text-[#2B384A] text-[12px] block mb-2"
                                          htmlFor=""
                                      >
                                          Description
                                      </label>
                                      <input
                                          className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                          type="text"
                                          {...register("description")}
                                      />
                                  </div>
                              </div>

                              <div>
                                  <div>
                                      <label
                                          className="text-[#2B384A] text-[12px] block mb-2"
                                          htmlFor=""
                                      >
                                          Status
                                      </label>
                                      <div className="flex">
                                          <p className="flex mr-3">
                                              <input
                                                  {...register("status", {
                                                      required:
                                                          "Status is required",
                                                  })}
                                                  type="radio"
                                                  value={true}
                                                  defaultChecked
                                              />
                                              <span className="text-[#2B384A] text-[12px] pl-2">
                                                  Active
                                              </span>
                                          </p>
                                          <p className="flex">
                                              <input
                                                  {...register("status", {
                                                      required:
                                                          "Status is required",
                                                  })}
                                                  type="radio"
                                                  value={false}
                                              />
                                              <span className="text-[#2B384A] text-[12px] pl-2">
                                                  Disable
                                              </span>
                                          </p>
                                      </div>
                                  </div>
                                  {errors.status && (
                                      <p
                                          className="text-xs text-[red] mt-1"
                                          role="alert"
                                      >
                                          {errors.status?.message}
                                      </p>
                                  )}
                              </div>
                          </div>
                          <div className="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between	">
                              <h4 className="text-[20px] text-left">
                                  Assign Materials
                              </h4>
                              {showFilteredProj.length > 0 && !isView && (
                                  <button
                                      onClick={showModal}
                                      className="bg-transparent h-[32px] w-[150px] border border-[#007AC8] rounded text-[#007AC8] text-[12px] font-bold uppercase"
                                  >
                                      Add Material
                                  </button>
                              )}
                          </div>
                          {selectingMat.length > 0 && (
                              <Table
                                  pagination={{ pageSize: 6 }}
                                  rowKey={(record) => record.material_id}
                                  className="w-full text-left"
                                  dataSource={selectingMat}
                                  columns={columns}
                              />
                          )}

                          <Modal
                              title="Assign Material"
                              open={isModalOpen}
                              cancelButtonProps={{ style: { display: "none" } }}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              width={"80%"}
                          >
                              <AssignMaterial
                                  addIndentMaterial={addIndentMaterial}
                                  materialList={showFilteredProj}
                                  selectedMaterial={isEdit ? selectingMat : undefined}
                              />
                          </Modal>
                      </div>
                      {!isView && (
                      <p className="text-right mt-8 flex items-center justify-end">
                          <button
                              onClick={closingModel}
                              className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                          >
                              Cancel
                          </button>
                          <button
                              type="Submit"
                              className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                              disabled={isLoading}
                          >
                              {isLoading ? (
                                  <BtnLoader />
                              ) : isEdit ? (
                                  "Update"
                              ) : (
                                  "Create"
                              )}
                          </button>
                      </p>
                      )}
                  </form>
              </div>
          </div>
      </div>
  );
}
