import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
    EMAIL_ONLY,
    PHONE_NO_ONLY,
    WEBSITE_ONLY,
} from "../../components/utils/Regex";
import { useQuery } from "@tanstack/react-query";
import { getCategoryList } from "../../queries/Categories";
import toast from "react-hot-toast";
import attachIcon from "../../assets/image/attachment-white.svg";
import deleteIcon from "../../assets/image/delete-white.svg";
import { uploadFilesVendor } from "../../queries/Vendors";
import { Link } from "react-router-dom";

export default function SupplierForm({isEdit, isView, vendor}) {
    const [uploadedFiles, setUploadedFiles] = useState(isEdit || isView ? vendor.attachments : []);
    const [currentFile, setCurrentFile] = useState([]);
    const [categoryName, setCategoryName] = useState(isEdit || isView ? vendor.category : "");
    
    const {
        register,
        formState: { errors },
        setValue,
        clearErrors,
    } = useFormContext();
    const { data: catData } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategoryList,
    });

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + uploadedFiles.length > 2) {
            toast.error("You can upload a maximum of 2 files.");
            setCurrentFile([]);
            return;
        }
        setCurrentFile([...currentFile, ...files]);
    };
    const uploadFiles = (e) => {
        e.preventDefault();
        const formData = new FormData();
        currentFile.forEach((file) => {
            formData.append("files", file);
        });
        const payload = {
            body: formData,
        };
        uploadFilesVendor(payload).then((res) => {
            setUploadedFiles([...uploadedFiles, ...res?.data]);
            setValue('attachments', [...uploadedFiles, ...res?.data])
            setCurrentFile([]);
        });
    };
    const deleteFile = (file) => {
        if (file?.size) {
            const newFiles = currentFile.filter((f) => f !== file);
            setCurrentFile(newFiles);
        } else {
            const newFiles = uploadedFiles.filter((f) => f !== file);
            setUploadedFiles(newFiles);
            setValue('attachments', newFiles)
        }
    };

    useEffect(() => {
        if(isEdit && vendor.attachments.length > 1){            
            clearErrors("gstCertificate")
        }
    }, [isEdit])

    return (
        <div>
            <div className="grid grid-cols-2 gap-8 text-left mt-5 mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="address"
                        >
                            Office Address <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="address"
                            type="text"
                            className={
                                errors?.address
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("address", {
                                required: "Supplier Address is required",
                            })}
                        />
                    </div>
                    {errors?.address && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.address?.message}
                        </p>
                    )}
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Bank Details
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.bank_name"
                        >
                            Bank Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.bank_name"
                            type="text"
                            className={
                                errors?.account_details?.bank_name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.bank_name", {
                                required: "Bank Name is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.bank_name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.bank_name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.branch_name"
                        >
                            Branch Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.branch_name"
                            type="text"
                            className={
                                errors?.account_details?.branch_name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.branch_name", {
                                required: "Branch Name is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.branch_name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.branch_name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.bank_account_no"
                        >
                            Account Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.bank_account_no"
                            type="text"
                            className={
                                errors?.account_details?.bank_account_no
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.bank_account_no", {
                                required: "Account Number is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.bank_account_no && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.bank_account_no?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.type_of_account"
                        >
                            Account Type <span className="text-[red]">*</span>
                        </label>
                        <select
                            className={
                                errors?.account_details?.type_of_account
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.type_of_account", {
                                required: "Account Type is required",
                            })}
                        >
                            <option value="Savings">Savings</option>
                            <option value="Current">Current</option>
                        </select>
                    </div>
                    {errors.account_details?.type_of_account && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.type_of_account?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.ifsc_code"
                        >
                            IFSC Code <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.ifsc_code"
                            type="text"
                            className={
                                errors?.account_details?.ifsc_code
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.ifsc_code", {
                                required: "IFSC Code is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.ifsc_code && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.ifsc_code?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="account_details.pan_no"
                        >
                            PAN Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="account_details.pan_no"
                            type="text"
                            className={
                                errors?.account_details?.pan_no
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("account_details.pan_no", {
                                required: "PAN Number is required",
                            })}
                        />
                    </div>
                    {errors?.account_details?.pan_no && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.account_details?.pan_no?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="gst"
                        >
                            GST <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="gst"
                            type="text"
                            className={
                                errors?.gst
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("gst", {
                                required: "GST Number is required",
                            })}
                        />
                    </div>
                    {errors?.gst && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.gst?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        {!isView && (<>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="gstCertificate"
                        >
                            Upload GST Certificate and Cancel Cheque Leaf (PNG, JPG, PDF and
                            max 2 Files)
                            <span className="text-[red]">*</span>
                        </label>
                        <input
                            type="file"
                            id="fileUpload"
                            accept=".jpg, .jpeg, .png, .pdf"
                            multiple
                            onChange={handleFileUpload}
                        />
                        <div className="mt-5">
                            {currentFile?.length > 0 && (
                                <>
                                    <p className="font-bold">Current File's</p>
                                    <ul>
                                        {currentFile?.map((file, index) => (
                                            <div
                                                key={index}
                                                className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between"
                                            >
                                                <p className="flex items-center text-[13px] text-gray-300">
                                                    <span className="pr-2">
                                                        <img
                                                            src={attachIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    {file?.file_name ||
                                                        file?.name}
                                                </p>
                                                <span
                                                    onClick={() =>
                                                        deleteFile(file)
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <img
                                                        src={deleteIcon}
                                                        alt=""
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {currentFile.length > 0 && (
                                <p className="text-right pt-3">
                                    <button
                                        className="font-bold text-[#007AC8]"
                                        onClick={(e) => uploadFiles(e)}
                                    >
                                        Upload
                                    </button>
                                </p>
                            )}
                        </div>
                        </>)}
                        
                        {uploadedFiles.length > 0 && (
                            <>
                                <p className="font-bold">Uploaded GST and Cancel Cheque Leaf File's</p>
                                <ul>
                                    {uploadedFiles?.map((file, index) => (
                                        <div
                                            key={index}
                                            className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between"
                                        >
                                            <Link
                                                to={file?.url}
                                                target="_blank"
                                                className="flex items-center text-[13px] text-gray-300"
                                            >
                                                <span className="pr-2">
                                                    <img
                                                        src={attachIcon}
                                                        alt=""
                                                    />
                                                </span>
                                                {file?.file_name || file?.name}
                                            </Link>
                                            {!isView && (<span
                                                onClick={() => deleteFile(file)}
                                                className="cursor-pointer"
                                            >
                                                <img src={deleteIcon} alt="" />
                                            </span>)}
                                            
                                        </div>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                    {uploadedFiles.length < 2 && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            GST Certificate and Cancel Cheque Leaf both is required
                        </p>
                    )}
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Contact Person Details - A
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.name"
                        >
                            Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.name"
                            type="text"
                            className={
                                errors?.contact_person_a?.name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.name", {
                                required: "Contact Person - A Name is required",
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.designation"
                        >
                            Designation <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.designation"
                            type="text"
                            className={
                                errors?.contact_person_a?.designation
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.designation", {
                                required:
                                    "Contact Person - A Designation is required",
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.designation && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.designation?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.phone_num"
                        >
                            Phone Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.phone_num"
                            type="text"
                            className={
                                errors?.contact_person_a?.phone_num
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.phone_num", {
                                required:
                                    "Contact Person - A Phone is required",
                                pattern: {
                                    value: PHONE_NO_ONLY,
                                    message: "Invalid Mobile Number",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.phone_num && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.phone_num?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_a.email"
                        >
                            Email <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_a.email"
                            type="text"
                            className={
                                errors?.contact_person_a?.email
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_a.email", {
                                required:
                                    "Contact Person - A Email is required",
                                pattern: {
                                    value: EMAIL_ONLY,
                                    message: "Invalid Email Address",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_a?.email && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_a?.email?.message}
                        </p>
                    )}
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Contact Person Details - B
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.name"
                        >
                            Name <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.name"
                            type="text"
                            className={
                                errors?.contact_person_b?.name
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.name", {
                                required: "Contact Person - B Name is required",
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.name && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.name?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.designation"
                        >
                            Designation <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.designation"
                            type="text"
                            className={
                                errors?.contact_person_b?.designation
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.designation", {
                                required:
                                    "Contact Person - B Designation is required",
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.designation && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.designation?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.phone_num"
                        >
                            Phone Number <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.phone_num"
                            type="text"
                            className={
                                errors?.contact_person_b?.phone_num
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.phone_num", {
                                required:
                                    "Contact Person - B Phone is required",
                                pattern: {
                                    value: PHONE_NO_ONLY,
                                    message: "Invalid Mobile Number",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.phone_num && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.phone_num?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="contact_person_b.email"
                        >
                            Email <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="contact_person_b.email"
                            type="text"
                            className={
                                errors?.contact_person_b?.email
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("contact_person_b.email", {
                                required:
                                    "Contact Person - B Email is required",
                                pattern: {
                                    value: EMAIL_ONLY,
                                    message: "Invalid Email Address",
                                },
                            })}
                        />
                    </div>
                    {errors?.contact_person_b?.email && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.contact_person_b?.email?.message}
                        </p>
                    )}
                </div>
            </div>
            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Additional Details
            </h4>
            <div className="grid grid-cols-2 gap-8 text-left mb-8">
                {(isEdit || isView) && (<div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="vendor_code"
                        >
                            Vendor Code <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="vendor_code"
                            type="text"
                            className={
                                errors?.vendor_code
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("vendor_code", {
                                required: "Vendor Code is required",
                            })}
                            disabled
                        />
                    </div>
                    {errors?.vendor_code && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.vendor_code?.message}
                        </p>
                    )}
                </div>)}
                
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor=""
                        >
                            Category <span className="text-[red]">*</span>
                        </label>

                        <select
                            className={
                                errors.category
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("category", {
                                required: "Category is required",
                            })}
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            {catData?.result.map((c) => (
                                <option key={c._id} value={c._id}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {errors.category && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.category?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="working_address"
                        >
                            Working Address{" "}
                            <span className="text-[red]">*</span>
                        </label>
                        <input
                            id="working_address"
                            type="text"
                            className={
                                errors?.working_address
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("working_address", {
                                required:
                                    "Supplier Working Address is required",
                            })}
                        />
                    </div>
                    {errors?.working_address && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.working_address?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="website"
                        >
                            Website
                        </label>
                        <input
                            id="website"
                            type="text"
                            className={
                                errors?.website
                                    ? "text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]"
                                    : "text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            }
                            {...register("website", {
                                required: false,
                                pattern: {
                                    value: WEBSITE_ONLY,
                                    message: "Invalid Web address",
                                },
                            })}
                        />
                    </div>
                    {errors?.website && (
                        <p className="text-xs text-[red] mt-1" role="alert">
                            {errors.website?.message}
                        </p>
                    )}
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="weekly_off"
                        >
                            Weekly Off
                        </label>
                        <input
                            id="weekly_off"
                            type="text"
                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                            {...register("weekly_off")}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="status_of_the_company"
                        >
                            Status of the Company
                        </label>
                        <select
                            className="border border-gray-300 text-[#2D3846] text-[16px] rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 font-bold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            {...register("status_of_the_company")}
                        >
                            <option value="Small Scale">Small Scale</option>
                            <option value="Medium Scale">Medium Scale</option>
                            <option value="Large Scale">Large Scale</option>
                            <option value="Ancillary">Ancillary</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div>
                        <label
                            className="text-[#2B384A] text-[12px] block mb-2"
                            htmlFor="type_of_the_company"
                        >
                            Type of the Company
                        </label>
                        <select
                            className="border border-gray-300 text-[#2D3846] text-[16px] rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 font-bold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            {...register("type_of_the_company")}
                        >
                            <option value="Public LTD">Public LTD</option>
                            <option value="Private LTD">Private LTD</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Proprietorship">
                                Proprietorship
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}
