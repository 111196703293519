import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm, Controller } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getUserList } from "../../queries/User";
import { getCustomerList } from "../../queries/Customers";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "antd";

export default function AddProject(props) {
    const {project, isLoading, adProject, isEdit, isView, closeModel, edProject } = props;
    const [userOptions, setUserOptions] = useState([]);

    const [startDate, setStartDate] = useState(isEdit || isView ? new Date(project.start_date) : null);
    const [endDate, setEndDate] = useState(isEdit || isView ? new Date(project.end_date) : null);

    const [duration, setDuration] = useState(isEdit || isView ? project.duration : 0);

    const [customerName, setCustomerName] = useState(isEdit || isView ? project.customer : "");
    
    const closingModel = () => {
        closeModel();
    };
    const { data: userData } = useQuery({
        queryKey: ["users"],
        queryFn: getUserList,
    });
    const { data: cusData } = useQuery({
        queryKey: ["customers"],
        queryFn: getCustomerList,
    });
    const {
        register,
        reset,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm(
        isEdit || isView ? { 
            defaultValues: {
                name: project.name,
                city: project.city,
                area: project.area,
                address: project.address,
                type: project.type,
                customer: project.customer,
                status: project.status,
                materials: project.materials,
                users: project.users.map(u => u._id)
            }
        } : { defaultValues: {
            name: "",
            city: "",
            area: "",
            address: "",
            type: "Commercial",
            customer: "",
            status: true,
            users: userOptions
        } }
    );

    const onSubmit = (data) => {
        if(isEdit){
            if (startDate !== null && endDate !== null) {
                let usrsData = [];
                data.users.map((u) => {
                  const temObj = {};
                  temObj._id = u;
                  usrsData.push(temObj);
                });
                const tempObj = {
                    id: project._id,
                    body: {
                        name: data.name,
                        city: data.city,
                        area: data.area,
                        address: data.address,
                        type: data.type,
                        customer: customerName,
                        materials: project.materials,
                        schedules: project.schedules,
                        start_date: moment(startDate).format("MM-DD-YYYY"),
                        end_date: moment(endDate).format("MM-DD-YYYY"),
                        duration: duration,
                        users: usrsData,
                        status: data.status !== "true" ? Boolean(false) : Boolean(true),
                    }
                };
                edProject(tempObj);
                closingModel();
            }
        }else{
            if (startDate !== null && endDate !== null) {
                let usrsData = [];
                data.users.map((u) => {
                const temObj = {};
                temObj._id = u;
                usrsData.push(temObj);
            });
                const tempObj = {
                    name: data.name,
                    city: data.city,
                    area: data.area,
                    address: data.address,
                    type: data.type,
                    customer: customerName,
                    materials: [],
                    schedules: [],
                    start_date: moment(startDate).format("MM-DD-YYYY"),
                    end_date: moment(endDate).format("MM-DD-YYYY"),
                    duration: duration,
                    users: usrsData,
                    status: data.status !== "true" ? Boolean(false) : Boolean(true),
                };

                console.log(tempObj);

                adProject(tempObj);
                closingModel();
            }
        }
        reset();
    };

    useEffect(() => {
        let usrList = [];
        userData?.result
            ?.filter((d) => d.status === true)
            .map((u) => {
                const temObj = {};
                temObj.value = u._id;
                temObj.label = `${u.first_name} ${u.last_name}`;
                usrList.push(temObj);
            });
        setUserOptions(usrList);
    }, [userData]);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const matColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (text) => <span>₹{text.toFixed(2)}</span>,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'HSN',
            dataIndex: 'hsn_code',
            key: 'hsn_code',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
        }
    ]

    const scheduleColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text) => <span>{text}%</span>,
        }
    ]

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            setDuration(moment(endDate).diff(moment(startDate), "days") + 1);
        }
    }, [startDate, endDate]);

    

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit ? "Edit Project" : isView ?  "View Project" : "Add New Project"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Enter Project details and add to list
                        {isEdit ? " or update project details" : isView ? " or view project details" : ""}
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Project Details
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Project Name <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Project Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            City
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("city")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Area <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.area ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("area", {
                                                required: "Area is required",
                                            })}
                                        />
                                    </div>
                                    {errors.area && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.area?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Address
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("address")}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Customer <span className="text-[red]">*</span>
                                        </label>

                                    

                                        <select
                                            className={errors.customer ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            {...register("customer", {
                                                required:
                                                    "Customer is required",
                                            })}
                                            value={customerName}
                                            onChange={(e) => setCustomerName(e.target.value)}
                                        >
                                            <option value="">
                                                Select Customer
                                            </option>
                                            {cusData?.result.map((c) => (
                                                <option
                                                    key={c._id}
                                                    value={c._id}
                                                >
                                                    {c.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors.customer && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.customer?.message}
                                        </p>
                                    )}
                                </div>
                                

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Project Type <span className="text-[red]">*</span>
                                        </label>
                                        <select
                                            className="border border-gray-300 text-[#2D3846] text-[16px] rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 font-bold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            {...register("type", {
                                                required:
                                                    "Project Type is required",
                                            })}
                                        >
                                            <option value="Interior Turnkey">
                                            Interior Turnkey
                                            </option>
                                            <option value="Civil">
                                                Civil
                                            </option>
                                            <option value="Residential">Residential</option>
                                        </select>
                                    </div>
                                    {errors.type && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.type?.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Start Date - End Date <span className="text-[red]">*</span>
                                        </label>
                                        <DatePicker
                                            className={startDate === null && endDate === null ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={onChange}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable={!isView ?? true}
                                        />
                                    </div>

                                    {startDate === null && endDate === null && (
                                        <p
                                        className="text-xs text-[red] mt-1"
                                        role="alert"
                                    >
                                        Please Select valid Start Date and
                                        End Date
                                    </p>
                                    )}
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Duration <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.duration ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("duration", {
                                                required:
                                                    "Please Select Start Date and End Date",
                                            })}
                                            value={duration}
                                        />
                                    </div>
                                    {errors.duration && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.duration?.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Assigned Employees
                                        </label>
                                        <Controller
                                            control={control}
                                            defaultValue={userOptions.map(
                                                (c) => c.value
                                            )}
                                            name="users"
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Select
                                                    value={userOptions.filter(
                                                        (c) =>
                                                            value.includes(
                                                                c.value
                                                            )
                                                    )}
                                                    isDisabled={isView}
                                                    onChange={(val) =>
                                                        onChange(
                                                            val.map(
                                                                (c) => c.value
                                                            )
                                                        )
                                                    }
                                                    options={userOptions}
                                                    isMulti
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Status
                                        </label>
                                        <div className="flex">
                                            <p className="flex mr-3">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    defaultChecked
                                                    value={true}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Active
                                                </span>
                                            </p>
                                            <p className="flex">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    value={false}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Disable
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    {errors.status && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.status?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            {(isView || isEdit) && (
                                <div className="mt-8">
                                <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Assigned Materials
                                </h4>

                                <Table
                                    pagination={{ pageSize: 4 }}
                                    className="w-full text-left"
                                    dataSource={project.materials}
                                    columns={matColumns}
                                />

                                </div>
                            )}
                            {(isView || isEdit) && (
                                <div>
                                <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Schedules
                                </h4>

                                <Table
                                    pagination={{ pageSize: 4 }}
                                    className="w-full text-left"
                                    dataSource={project.schedules}
                                    columns={scheduleColumns}
                                />

                                </div>
                            )}
                        </div>
                        {!isView && (
                            <p className="text-right mt-8 flex items-center justify-end">
                            <button
                                onClick={closingModel}
                                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                            >
                                Cancel
                            </button>
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
                            </button>
                        </p>
                        )}
                        
                    </form>
                </div>
            </div>
        </div>
    );
}
