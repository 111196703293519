import React, {useState, useEffect} from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getProjectList } from '../../queries/Projects';
import { getCategoryList } from '../../queries/Categories';
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

export default function AddSchedule(props) {
  const {isLoading, isEdit, isView, closeModel, schedule, adSchedule, edSchedule} = props;
  const [dateRange, setDateRange] = useState([isEdit || isView ? new Date(schedule.start_date) : null, isEdit || isView ? new Date(schedule.end_date) : null]);
  const [duration, setDuration] = useState(isEdit || isView ? schedule.days : 0);
  const [projectName, setProjectName] = useState(isEdit || isView ? schedule.project_id : "");
  const [categoryName, setCategoryName] = useState(isEdit || isView ? schedule.category : "");
  const [matStartDate, setMatStartDate] = useState(new Date());
  const [startDate, endDate] = dateRange;
  const closingModel = () => {
    closeModel();
  };
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm(
    isEdit || isView ? {
      defaultValues: {
        name: schedule.name,
        project_id: schedule.project_id,
        category: schedule.category,
        material_required_date: moment(schedule.material_required_date).format("MM-DD-YYYY"),
        start_date: moment(schedule.start_date).format("MM-DD-YYYY"),
        end_date: moment(schedule.end_date).format("MM-DD-YYYY"),
        duration: schedule.days,
        status: schedule.status.toString(),
      },
    } : {
    defaultValues: {
      name: "",
      project_id: "",
      category: "",
      material_required_date: "",
      start_date: "",
      end_date: "",
      duration: "",
      status: "",
    },
  });

  const { data: projData } = useQuery({
    queryKey: ["projects"],
    queryFn: getProjectList,
  });
  const { data: cateData } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoryList,
  });

  const onSubmit = (data) => {
    if(isEdit) {
      const tempObj = {
        id: schedule._id,
        body: {
          name: data.name,
          project_id: projectName,
          category: categoryName,
          material_required_date: moment(matStartDate).format("MM-DD-YYYY"),
          start_date: moment(startDate).format("MM-DD-YYYY"),
          end_date: moment(endDate).format("MM-DD-YYYY"),
          days: duration,
          status: data.status !== "true" ? Boolean(false) : Boolean(true),
        }
      };
      edSchedule(tempObj);
      closingModel();
    }else{
      const tempObj = {
          name: data.name,
          project_id: projectName,
          category: categoryName,
          material_required_date: moment(matStartDate).format("MM-DD-YYYY"),
          start_date: moment(startDate).format("MM-DD-YYYY"),
          end_date: moment(endDate).format("MM-DD-YYYY"),
          days: duration,
          status: data.status !== "true" ? Boolean(false) : Boolean(true),
      };
      adSchedule(tempObj);
      closingModel();
    }
    reset();
  };

  useEffect(() => {
    if(startDate !== null && endDate !== null){
      setDuration(moment(endDate).diff(moment(startDate), 'days')+1);
    }
  }, [startDate, endDate, dateRange, setDateRange])

  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[40%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">
              {isEdit ? "Edit Schedule" : isView ?  "View Schedule" : "Add New Schedule"}
          </h3>
          <p className="text-[14px] text-[#656F7D]">
              Enter Project details and add to list
              {isEdit ? " or update schedule details" : isView ? " or view schedule details" : ""}
          </p>
        </div>
        <div className="w-[60%]">
          <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
            <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Schedule Details
              </h4>

              <div className="grid grid-cols-2 gap-8 text-left">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Schedule Name <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("name", {
                        required: "Schedule Name is required",
                      })}
                    />
                  </div>
                  {errors.name && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.name?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Project <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.project_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("project_id", {
                        required: "Please Select Project",
                      })}
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                    >
                      <option value="">Select Project</option>
                      {projData?.result.map((p) => (
                        <option key={p._id} value={p._id}>
                          {p.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.project_id && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.project_id?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Category <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.category ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("category", {
                        required: "Please Select Category",
                      })}
                      value={categoryName}
                      onChange={(e) => {setCategoryName(e.target.value)}}
                    >
                      <option value="">Select Category</option>
                      {cateData?.result.map((c) => (
                        <option key={c._id} value={c._id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.category && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.category?.message}
                    </p>
                  )}
                </div>
                
                
                
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Material Required Date <span className="text-[red]">*</span>
                    </label>
                    <DatePicker dateFormat="dd/MM/yyyy" className={!matStartDate ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'} selected={matStartDate} onChange={(date) => {setMatStartDate(date); setDateRange([matStartDate, null])}} />
                  </div>
                  {!matStartDate && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      Please Select valid Material Required Date
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Start Date - End Date <span className="text-[red]">*</span>
                    </label>
                    <DatePicker
                      className={dateRange.filter(r => r !== null).length < 2 ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      selectsRange={true}
                      minDate={matStartDate}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={!isView ?? true}
                    />
                  </div>
                  {dateRange.filter(r => r !== null).length < 2 && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      Please Select valid Start Date and End Date
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Total Days
                    </label>
                    <input
                      className={'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("duration")}
                      value={duration}
                    />
                  </div>
                  {/* {errors.duration && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.duration?.message}
                    </p>
                  )} */}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Status
                    </label>
                    <div className="flex">
                      <p className="flex mr-3">
                        <input
                          {...register("status", {
                            required: "Status is required",
                          })}
                          type="radio"
                          defaultChecked
                          value={true}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          Active
                        </span>
                      </p>
                      <p className="flex">
                        <input
                          {...register("status", {
                            required: "Status is required",
                          })}
                          type="radio"
                          value={false}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          Disable
                        </span>
                      </p>
                    </div>
                  </div>
                  {errors.status && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.status?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {!isView && (
            <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={isLoading}
              >
                {isLoading ? (
                    <BtnLoader />
                ) : isEdit ? (
                    "Update"
                ) : (
                    "Create"
                )}
              </button>
            </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
