import React, { useState} from "react";
import loginBg from "../assets/image/login-bg.jpg";
import logo from "../assets/image/sd-logo.png";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useQueryMutation } from "../hooks/useMutation";
import { resetPassword } from "../queries/User";

const ForgotPassword = () => {
    const { token } = useParams();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const queryClient = useQueryClient();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {mutate: resetPassQuery, isLoading} = useQueryMutation(resetPassword, queryClient, "users");

  const onSubmit = (data) => {
    const resetObj = {
        password: data.confirmPassword,
        token: token
    }
    if(password !== '' && confirmPassword !== '' && password === confirmPassword){
        resetPassQuery(resetObj)
    }else{
        toast.error('Please enter your password with valid format and match with Confirm Password');
    }
  }


  return (
    <div className="lg:flex">
      <div className="w-1/2 overflow-x-hidden lg:block hidden">
        <img className="object-cover w-full h-full" src={loginBg} alt="" />
      </div>
      <div className="lg:w-1/2 w-full grid justify-center items-end">
        <div className="lg:w-[500px] w-full px-10 lg:px-0">
          <p className="flex justify-center mb-6">
            <img src={logo} width="35%" alt="" />
          </p>

          <>
              <div className="mb-8">
                <h1 className="text-[34px] text-[#2D3846] font-bold">
                  Reset Password
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="text-left mb-5">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-[14px] font-medium text-[#2B384A]"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="6.75"
                          width="14.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      type="password"
                      id="password"
                      className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Password"
                      {...register("password", {
                        required: "New password is required",
                        pattern: {
                            value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: "Password must be at least 8 characters long and contain an uppercase letter, a number, and a special character.",
                          },
                      })}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  {errors.password && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.password?.message}
                    </p>
                  )}
                </div>
                <div className="text-left mb-5">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-[14px] font-medium text-[#2B384A]"
                  >
                    Confirm New Password
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="6.75"
                          width="14.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                          stroke="#185D9E"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      type="password"
                      id="confirmPassword"
                      className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Password"
                      {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: {
                            match: (value) => value === password || "Passwords do not match",  // Custom validation to check if passwords match
                          },
                      })}
                      autoComplete="off"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.confirmPassword?.message}
                    </p>
                  )}
                </div>
                
                <button
                  disabled={isLoading}
                  type="Submit"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "30px auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="font-bold disabled:opacity-25 text-[14px] bg-[#007AC8] w-full py-4 rounded-lg text-white"
                >
                  <div style={{ mr: 1 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      fill="white"
                      height="18"
                      viewBox="0 0 512 512"
                    >
                      <path d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
                    </svg>
                  </div>
                  Reset
                </button>
              </form>
            </>

          <div className="mt-8 bg-slate-300 p-6 rounded-md">
            <p className="text-[12px]">
              For any queries please click the <strong>User Guide</strong>,
              Contact <strong className="text-[#007AC8]">8925965077</strong> or{" "}
              <strong className="text-[#007AC8]">it@spacedraft.in</strong> from
              9AM to 6PM
            </p>
            <p className="text-right">
              <button className="bg-[#355d76] text-white text-[12px] py-1 px-3">
                User Guide
              </button>
            </p>
          </div>
        </div>

        <p className="text-[12px] text-center my-5">
          &copy; All rights reserved 2025 Spacedraft India Pvt, Ltd.
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
