import React, {useState} from 'react';
import Materials from './material/Materials';
import Categories from './category/Categories';
import TabComponent from '../components/TabComponent';
import useModulePermissions from '../hooks/useModulePermissions';
import Stocks from './stock/Stocks';

const MaterialsMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const [activeTab, setActiveTab] = useState(permissions.showMaterialMenu !== undefined ? '1' : permissions.showCategoryMenu !== undefined ? '2' : '3');
  const tabData = [
    { permission: 'showMaterialMenu', id: '1', tabTitle: 'Materials', title: 'Materials' },
    { permission: 'showCategoryMenu', id: '2', tabTitle: 'Categories', title: 'Categories' },
    { permission: 'showStockMenu', id: '3', tabTitle: 'Stock History', title: 'Stock History' },
  ];

  return (
    <>
    <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Material Overview</h3>
    <TabComponent 
      tabData={tabData} 
      activeTab={activeTab} 
      setActiveTab={setActiveTab} 
      permissions={permissions} 
    />
    {activeTab === '1' && permissions.showMaterialMenu !== undefined && <Materials />}
    {activeTab === '2' && permissions.showCategoryMenu !== undefined && <Categories />}
    {activeTab === '3' && permissions.showStockMenu !== undefined && <Stocks />}
    </>
    
  )
};

export default MaterialsMain;