import {
    endPoints,
    getAuthorizationHeader,
    axiosInstance,
  } from "../components/api";

export const getStockList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.stock}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

// export const addIndent = async (reqBody) => {
//   const { data: response } = await axiosInstance.post(
//     `${endPoints.indent}`,
//     reqBody,
//     {
//       headers: { Authorization: getAuthorizationHeader() },
//     }
//   );
//   return response;
// };


// export const getIndentById = async (id) => {
//   try {
//     const { data: response } = await axiosInstance.get(`${endPoints.indent}/${id}`, {
//       headers: { Authorization: getAuthorizationHeader() },
//     });
//     return response;
//   } catch (error) {
//     return error.response;
//   }
// };


// export const editIndent = async (ind) => {
//   const { data: response } = await axiosInstance.put(
//     `${endPoints.indent}/${ind.id}`,
//     ind.body,
//     {
//       headers: { Authorization: getAuthorizationHeader() },
//     }
//   );
//   return response;
// };

// export const deleteIndent = async (indId) => {
//   const { data: response } = await axiosInstance.delete(
//     `${endPoints.indent}/${indId}`,
//     {
//       headers: { Authorization: getAuthorizationHeader() },
//     }
//   );
//   return response;
// };

// export const indentApproval = async (indObj) => {
//   const { data: response } = await axiosInstance.post(
//     `${endPoints.indent}/sub/approval/${indObj.id}`,
//     indObj.body,
//     {
//       headers: { Authorization: getAuthorizationHeader() },
//     }
//   );
//   return response;
// };
